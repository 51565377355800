import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JsonScalar: any;
  Upload: any;
};

export enum AdminDeviceScreenStatus {
  Auto = 'AUTO',
  Off = 'OFF',
  On = 'ON'
}

export type AssignUserInput = {
  companyId: Scalars['ID'];
  role: Roles;
  /** List user's id for assign  */
  userIds: Array<Scalars['ID']>;
};

export type Availability = {
  __typename?: 'Availability';
  enabled?: Maybe<Scalars['Boolean']>;
  fromDate?: Maybe<Scalars['Float']>;
  toDate?: Maybe<Scalars['Float']>;
};

export type AvailabilityInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  fromDate?: Maybe<Scalars['Float']>;
  toDate?: Maybe<Scalars['Float']>;
};

export type BlockUserInput = {
  _id: Scalars['ID'];
  status: User_Status;
};

export enum Capacity {
  Fifty = 'FIFTY',
  Hundred = 'HUNDRED',
  Ten = 'TEN',
  Twenty = 'TWENTY'
}

export type CaptureScreenInput = {
  file: Scalars['String'];
};

export type ChangePassRess = {
  __typename?: 'ChangePassRess';
  token?: Maybe<Scalars['String']>;
  updated: Scalars['Boolean'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  /** id */
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  address_latitude?: Maybe<Scalars['Float']>;
  address_longitude?: Maybe<Scalars['Float']>;
  admins?: Maybe<Array<User>>;
  capacity: Scalars['Float'];
  createdAt: Scalars['Float'];
  createdBy: User;
  createdById: Scalars['ID'];
  /** Dung lượng file thực tế đã upload (MB) */
  currentStorageSize?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy: Scalars['ID'];
  deletedByUser: User;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  endDate?: Maybe<Scalars['Float']>;
  /** Dung lượng file có thể upload (MB) */
  limitUpload?: Maybe<Scalars['Float']>;
  managerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  openTimes?: Maybe<Array<OpenTime>>;
  phone: Scalars['String'];
  startDate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
  userLimit: Scalars['Float'];
  userRole?: Maybe<Roles>;
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  /** id */
  _id: Scalars['ID'];
  companies?: Maybe<Array<Company>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedByUser?: Maybe<User>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** full name */
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role: Roles;
  status: User_Status;
  tokenExpired?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
  username: Scalars['String'];
};

export type ConnectWifiInput = {
  _id: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  ssid: Scalars['String'];
};

export type CreateCompanyInput = {
  address?: Maybe<Scalars['String']>;
  address_latitude?: Maybe<Scalars['Float']>;
  address_longitude?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Capacity>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  endDate?: Maybe<Scalars['Float']>;
  limitUpload: Scalars['Float'];
  name: Scalars['String'];
  openTimes?: Maybe<Array<OpenTimeInput>>;
  phone: Scalars['String'];
  startDate?: Maybe<Scalars['Float']>;
  userLimit?: Maybe<UserLimit>;
};

export type CreateDeviceInput = {
  companyId?: Maybe<Scalars['ID']>;
  deviceCode?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  os?: Maybe<OsType>;
  schedules?: Maybe<Array<DevicePlaylistScheduleInput>>;
  screen?: Maybe<ScreenType>;
  yearOfManufacture?: Maybe<Scalars['Float']>;
};

export type CreateFolderInput = {
  filename: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type CreateGroupInput = {
  deviceIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type CreatePlaylistInput = {
  companyId: Scalars['ID'];
  frames: Array<FrameInputType>;
  name: Scalars['String'];
};

export type CreateTokenAutoLoginInput = {
  companyId: Scalars['String'];
};

export type CreateUploadSettingInput = {
  key: SettingType;
  value: Array<UploadSettingDataInput>;
};

export type CreateUserInput = {
  companyId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
  username: Scalars['String'];
};

export type DailyScheduleData = {
  __typename?: 'DailyScheduleData';
  dateRange?: Maybe<DateRange>;
  timeInDay?: Maybe<TimeInDay>;
};

export type DailyScheduleInputData = {
  dateRange?: Maybe<DateRangeInput>;
  timeInDay?: Maybe<TimeInDayInput>;
};

export type DateRange = {
  __typename?: 'DateRange';
  /** Time start */
  dateFrom: Scalars['String'];
  /** Time end */
  dateTo: Scalars['String'];
};

export type DateRangeInput = {
  /** Time start */
  dateFrom: Scalars['String'];
  /** Time end */
  dateTo: Scalars['String'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DayOff = {
  __typename?: 'DayOff';
  /** id */
  _id: Scalars['ID'];
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
};

export type DeleteCompanyInput = {
  _id: Scalars['String'];
};

export type DeleteDeviceInput = {
  _id: Scalars['ID'];
};

export type DeleteGroupInput = {
  _id: Scalars['ID'];
};

export type DeleteMediaInput = {
  _id: Scalars['ID'];
};

export type DeletePlaylistInput = {
  _id: Scalars['ID'];
};

export type Device = {
  __typename?: 'Device';
  /** id */
  _id: Scalars['ID'];
  adminScreenStatus?: Maybe<AdminDeviceScreenStatus>;
  /** Pi api run at */
  appBackendStartAt?: Maybe<Scalars['Float']>;
  /** chrome current run at */
  appFrontendStartAt?: Maybe<Scalars['Float']>;
  appVersion?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Float'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  currentPlaylist?: Maybe<Playlist>;
  /** field cũ, sử dụng currentPlaylistIds thay thế */
  currentPlaylistId?: Maybe<Scalars['ID']>;
  currentPlaylistIds?: Maybe<Array<Scalars['String']>>;
  currentPlaylists?: Maybe<Array<Playlist>>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedByUser?: Maybe<User>;
  /** match với mã trên thiết bị */
  deviceCode?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  direction?: Maybe<DirectionRotateType>;
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['ID']>;
  groupPending?: Maybe<Scalars['ID']>;
  hideClock?: Maybe<Scalars['Boolean']>;
  /** Không dùng đến, dùng trường lastGetInfoAt thay thế */
  lastConnect?: Maybe<Scalars['String']>;
  lastGetInfoAt?: Maybe<Scalars['Float']>;
  lastScreenshot?: Maybe<Scalars['String']>;
  localTime?: Maybe<Scalars['String']>;
  /** Địa chỉ mac của thiết bị thật */
  macAddress?: Maybe<Scalars['String']>;
  momentDateFormat?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offByAdmin?: Maybe<Scalars['Boolean']>;
  os?: Maybe<OsType>;
  /** Chuyển schedule sang group device */
  schedules?: Maybe<Array<DevicePlaylistSchedule>>;
  screen?: Maybe<ScreenType>;
  screenStatus?: Maybe<DeviceScreenStatus>;
  status?: Maybe<DeviceStatus>;
  subscriber?: Maybe<SubscriberType>;
  subscriptionDate?: Maybe<SubscriptionDate>;
  /** Device token sinh ra khi api publicGetDeviceByCode được gọi (device đc gán vào 1 thiết bị thật) */
  token?: Maybe<Scalars['String']>;
  /** Bỏ field này */
  uniqueCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Float']>;
  wifi?: Maybe<WifiType>;
  yearOfManufacture?: Maybe<Scalars['Float']>;
};

export type DevicePlaylistSchedule = {
  __typename?: 'DevicePlaylistSchedule';
  createdById?: Maybe<Scalars['String']>;
  dailyData?: Maybe<DailyScheduleData>;
  monthlyData?: Maybe<MonthlyScheduleData>;
  playlist?: Maybe<Playlist>;
  playlistId: Scalars['ID'];
  specialDayData?: Maybe<Array<SpecialDayScheduleData>>;
  type: ScheduleType;
  weeklyData?: Maybe<WeeklyScheduleData>;
};

export type DevicePlaylistScheduleInput = {
  createdById: Scalars['String'];
  dailyData?: Maybe<DailyScheduleInputData>;
  monthlyData?: Maybe<MonthlyScheduleInputData>;
  playlistId: Scalars['ID'];
  specialDayData?: Maybe<Array<SpecialDayScheduleInputData>>;
  type: ScheduleType;
  weeklyData?: Maybe<WeeklyScheduleInputData>;
};

export type DeviceProxySession = {
  __typename?: 'DeviceProxySession';
  /** The time in seconds when the device connected to the proxy server */
  connectedAt?: Maybe<Scalars['Float']>;
  deviceCode: Scalars['String'];
  deviceId: Scalars['String'];
  proxyUrl?: Maybe<Scalars['String']>;
  /** The time created the proxy session */
  startedAt: Scalars['Float'];
};

export type DeviceProxySessionInput = {
  proxyUrl: Scalars['String'];
};

export type DevicePubSub = {
  __typename?: 'DevicePubSub';
  data: Scalars['JsonScalar'];
  type: DevicePubSubType;
};

export enum DevicePubSubType {
  AdminForceUpdateScreenStatus = 'ADMIN_FORCE_UPDATE_SCREEN_STATUS',
  CaptureScreen = 'CAPTURE_SCREEN',
  ClearDeviceStorage = 'CLEAR_DEVICE_STORAGE',
  ConnectWifi = 'CONNECT_WIFI',
  Deleted = 'DELETED',
  DeviceCodeUpdated = 'DEVICE_CODE_UPDATED',
  NewCaptureScreen = 'NEW_CAPTURE_SCREEN',
  NewData = 'NEW_DATA',
  ReloadClientApp = 'RELOAD_CLIENT_APP',
  RestartDevice = 'RESTART_DEVICE',
  RotateScreen = 'ROTATE_SCREEN',
  ScanWifiDevice = 'SCAN_WIFI_DEVICE',
  SendGroupMessage = 'SEND_GROUP_MESSAGE',
  SendWifi = 'SEND_WIFI',
  StatusConnectWifi = 'STATUS_CONNECT_WIFI'
}

export enum DeviceScreenStatus {
  Off = 'OFF',
  On = 'ON'
}

export enum DeviceStatus {
  LostConnection = 'LOST_CONNECTION',
  NoConnection = 'NO_CONNECTION',
  NoPlaylist = 'NO_PLAYLIST',
  Off = 'OFF',
  On = 'ON'
}

export enum DirectionRotateType {
  Inverted = 'INVERTED',
  Left = 'LEFT',
  Normal = 'NORMAL',
  Right = 'RIGHT'
}

export enum DisplayMode {
  Actual = 'ACTUAL',
  Fill = 'FILL',
  Fit = 'FIT',
  Stretch = 'STRETCH'
}

export enum FileType {
  File = 'FILE',
  Folder = 'FOLDER'
}

export type ForceUpdateScreenStatusInput = {
  _id: Scalars['ID'];
  status: AdminDeviceScreenStatus;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type Frame = {
  __typename?: 'Frame';
  availability?: Maybe<Availability>;
  /** chứa ảnh của video hoặc document đã qua xử lý sau khi playlist được tạo */
  documentImages?: Maybe<Array<MediaImage>>;
  duration: Scalars['Float'];
  /** chứa thông tin image đã qua xử lý sau khi playlist được tạo */
  image?: Maybe<MediaImage>;
  media?: Maybe<Media>;
  /** Phòng trường hợp media bị xóa thì khi update playlist có thể check và bỏ frame này khỏi list */
  mediaId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  type: FrameType;
  /** chứa video profiles đã qua xử lý sau khi playlist được tạo */
  videoProfiles?: Maybe<Array<VideoProfile>>;
  webUrl?: Maybe<Scalars['String']>;
};

export enum FrameDirection {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL'
}

export type FrameInputType = {
  availability?: Maybe<AvailabilityInput>;
  duration: Scalars['Float'];
  media?: Maybe<MediaInput>;
  /** Phòng trường hợp media bị xóa thì khi update playlist có thể check và bỏ frame này khỏi list */
  mediaId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  type: FrameType;
  webUrl?: Maybe<Scalars['String']>;
};

export enum FrameType {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Video = 'VIDEO',
  WebUrl = 'WEB_URL'
}

export type Group = {
  __typename?: 'Group';
  /** id */
  _id: Scalars['ID'];
  approvedById?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  companyId: Scalars['ID'];
  createdAt: Scalars['Float'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deviceIds?: Maybe<Array<Scalars['ID']>>;
  devices?: Maybe<Array<Device>>;
  editingById?: Maybe<Scalars['ID']>;
  layoutSettings?: Maybe<ScheduleLayoutSettings>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  /** Khởi động lại thiết bị hàng ngày */
  rebootDeviceEveryDay?: Maybe<Scalars['Boolean']>;
  rejectedById?: Maybe<Scalars['ID']>;
  scheduleVersion?: Maybe<Scalars['Float']>;
  schedules?: Maybe<Array<DevicePlaylistSchedule>>;
  schedulesSecond?: Maybe<Array<DevicePlaylistSchedule>>;
  showClock?: Maybe<Scalars['Boolean']>;
  status?: Maybe<GroupStatus>;
  timezone: Scalars['String'];
  /** Dừng chạy lịch, (dừng chạy tạm thời, không phải xóa) */
  turnOffSchedule?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
};

export enum GroupMessageDirection {
  LeftToRight = 'LEFT_TO_RIGHT',
  RightToLeft = 'RIGHT_TO_LEFT'
}

export enum GroupMessageSpeed {
  Fast = 'FAST',
  Medium = 'MEDIUM',
  Slow = 'SLOW'
}

export enum GroupStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  WaitToApprove = 'WAIT_TO_APPROVE'
}

export type Jwt = {
  __typename?: 'JWT';
  companyId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['Int'];
  isSuperAdmin: Scalars['Boolean'];
  payload: User;
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Int'];
  role: Roles;
  token: Scalars['String'];
};


export type KickOutCompanyInput = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  _id: Scalars['ID'];
  canEdit: Scalars['Boolean'];
  clanIds?: Maybe<Array<Scalars['ID']>>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Float']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  /** ảnh documents, ảnh video sau khi tạo playlist */
  images?: Maybe<Array<MediaImage>>;
  /** aws transcode jobId */
  jobId?: Maybe<Scalars['String']>;
  mediaProcess?: Maybe<Media>;
  mediaType?: Maybe<MediaType>;
  metadata?: Maybe<Scalars['JsonScalar']>;
  mimetype?: Maybe<Scalars['String']>;
  optimizePath?: Maybe<Scalars['String']>;
  /** Link sau khi convert/optimize */
  optimizeUrl?: Maybe<Scalars['String']>;
  /** Link ban đầu */
  originalUrl?: Maybe<Scalars['String']>;
  parent?: Maybe<Media>;
  parentId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  /** Worker process retries time */
  retry?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  status: MediaStatus;
  /** Ảnh thumbs */
  thumbs?: Maybe<Array<MediaThumb>>;
  /** file or folder */
  type: FileType;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
  /** video profile sau khi tạo playlist */
  videoProfiles?: Maybe<Array<VideoProfile>>;
  width?: Maybe<Scalars['Int']>;
};

export enum MediaFilterType {
  Image = 'IMAGE',
  Pdf = 'PDF',
  Powerpoint = 'POWERPOINT',
  Video = 'VIDEO'
}

export type MediaImage = {
  __typename?: 'MediaImage';
  height?: Maybe<Scalars['Float']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type MediaImageInput = {
  height?: Maybe<Scalars['Float']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type MediaInput = {
  _id: Scalars['ID'];
  canEdit?: Maybe<Scalars['Boolean']>;
  clanIds?: Maybe<Array<Scalars['ID']>>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdById?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Float']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  /** ảnh documents, ảnh video */
  images?: Maybe<Array<MediaImageInput>>;
  jobId?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  metadata?: Maybe<Scalars['JsonScalar']>;
  mimetype?: Maybe<Scalars['String']>;
  optimizePath?: Maybe<Scalars['String']>;
  /** Link sau khi convert/optimize */
  optimizeUrl?: Maybe<Scalars['String']>;
  /** Link ban đầu */
  originalUrl?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  status?: Maybe<MediaStatus>;
  thumbs?: Maybe<Array<MediaThumbInput>>;
  /** file or folder */
  type: FileType;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedById?: Maybe<Scalars['ID']>;
  videoProfiles?: Maybe<Array<VideoProfileInput>>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaOrderBy = {
  direction: Sort;
  field: MediaOrderField;
};

export enum MediaOrderField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Type = 'TYPE'
}

export enum MediaStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Processing = 'PROCESSING'
}

export type MediaThumb = {
  __typename?: 'MediaThumb';
  path: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type MediaThumbInput = {
  path: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export enum MediaType {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Pdf = 'PDF',
  Powerpoint = 'POWERPOINT',
  Video = 'VIDEO'
}

export type MonthInYear = {
  __typename?: 'MonthInYear';
  days: Array<Scalars['Int']>;
  month: Scalars['Int'];
};

export type MonthInYearInput = {
  days: Array<Scalars['Int']>;
  month: Scalars['Int'];
};

export type MonthlyScheduleData = {
  __typename?: 'MonthlyScheduleData';
  months?: Maybe<Array<MonthInYear>>;
  timeInDay?: Maybe<TimeInDay>;
};

export type MonthlyScheduleInputData = {
  months: Array<MonthInYearInput>;
  timeInDay?: Maybe<TimeInDayInput>;
};

export type MoveMediaInput = {
  _id: Scalars['ID'];
  parentId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminForceUpdateScreenStatus?: Maybe<Device>;
  approveAllGroup: Scalars['Boolean'];
  assignUserToCompany: Scalars['Float'];
  blockUser?: Maybe<User>;
  captureScreenshot: Scalars['Boolean'];
  changePassword: ChangePassRess;
  clearDeviceStorage: Scalars['Boolean'];
  closeSessions: Scalars['Boolean'];
  connectWifiDevice?: Maybe<Scalars['Boolean']>;
  createCompany?: Maybe<Company>;
  createDevice?: Maybe<Device>;
  createFolder?: Maybe<Media>;
  createGroup?: Maybe<Group>;
  createPlaylist?: Maybe<Playlist>;
  /** Call from cms to create proxy session, if session is exist add more time */
  createSession: Scalars['Boolean'];
  createTokenAutoLogin: NewTokenAutoLogin;
  createUploadSetting?: Maybe<Setting>;
  createUser?: Maybe<User>;
  delPlaylist: Scalars['Boolean'];
  deleteCompany?: Maybe<Scalars['Boolean']>;
  deleteDevice: Scalars['Boolean'];
  deleteFile?: Maybe<Scalars['Boolean']>;
  deleteGroup?: Maybe<Scalars['Boolean']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  forgotPassword: Scalars['Boolean'];
  kickOutCompany: Scalars['Boolean'];
  login: Jwt;
  logout: Scalars['Boolean'];
  moveFile?: Maybe<Media>;
  publicUpdateDeviceInfo?: Maybe<Device>;
  publicUpdateDeviceScreenStatus?: Maybe<Device>;
  publicUpdateDeviceStatus?: Maybe<Device>;
  publicUpdateRotateScreen: Scalars['Boolean'];
  refreshToken: Jwt;
  rejectAllGroup: Scalars['Boolean'];
  reloadClientApp: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetPasswordUser: Scalars['Boolean'];
  restartDevice: Scalars['Boolean'];
  restoreUser?: Maybe<Scalars['Boolean']>;
  rotateScreen: Device;
  scanWifiDevice?: Maybe<Array<WifiType>>;
  sendGroupMessage: Scalars['Boolean'];
  sendScreenshot?: Maybe<Scalars['Boolean']>;
  sendWifiDevice?: Maybe<Scalars['Boolean']>;
  settingDevice?: Maybe<Device>;
  statusConnectWifi?: Maybe<Scalars['Boolean']>;
  updateCompany?: Maybe<Company>;
  updateDevice?: Maybe<Device>;
  updateDeviceCurrentPlaylists?: Maybe<Device>;
  updateDeviceScreenStatus?: Maybe<Device>;
  updateDeviceStatus?: Maybe<Device>;
  updateFile?: Maybe<Media>;
  updateGroup?: Maybe<Group>;
  updateGroupPlaylistSchedules?: Maybe<Group>;
  updateGroupStatus?: Maybe<Group>;
  /** Call from pi to set proxy url */
  updateMySessionUrl?: Maybe<Scalars['Boolean']>;
  updatePlaylist?: Maybe<Playlist>;
  updatePlaylistGroup: Scalars['Boolean'];
  updatePlaylistToOtherGroup: Scalars['Boolean'];
  updateProfile: User;
  updateTimeZoneGroup?: Maybe<Group>;
  updateUser?: Maybe<User>;
  uploadFile?: Maybe<Media>;
  verifyTokenAutoLogin: Jwt;
};


export type MutationAdminForceUpdateScreenStatusArgs = {
  input: ForceUpdateScreenStatusInput;
};


export type MutationAssignUserToCompanyArgs = {
  assignUserInput: AssignUserInput;
};


export type MutationBlockUserArgs = {
  input: BlockUserInput;
};


export type MutationCaptureScreenshotArgs = {
  input: QueryDeviceInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationClearDeviceStorageArgs = {
  input: QueryDeviceInput;
};


export type MutationConnectWifiDeviceArgs = {
  input: ConnectWifiInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreatePlaylistArgs = {
  input: CreatePlaylistInput;
};


export type MutationCreateSessionArgs = {
  input: QueryDeviceInput;
};


export type MutationCreateTokenAutoLoginArgs = {
  input: CreateTokenAutoLoginInput;
};


export type MutationCreateUploadSettingArgs = {
  input: CreateUploadSettingInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDelPlaylistArgs = {
  input: DeletePlaylistInput;
};


export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};


export type MutationDeleteDeviceArgs = {
  input: DeleteDeviceInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteMediaInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteUserArgs = {
  input: QueryUserInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationKickOutCompanyArgs = {
  input: KickOutCompanyInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLogoutArgs = {
  input: Scalars['String'];
};


export type MutationMoveFileArgs = {
  input: MoveMediaInput;
};


export type MutationPublicUpdateDeviceInfoArgs = {
  input: PublicUpdateDeviceInfoInput;
};


export type MutationPublicUpdateDeviceScreenStatusArgs = {
  input: PublicUpdateDeviceScreenStatusInput;
};


export type MutationPublicUpdateDeviceStatusArgs = {
  input: PublicUpdateDeviceStatusInput;
};


export type MutationPublicUpdateRotateScreenArgs = {
  input: PublicUpdateRotateInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationReloadClientAppArgs = {
  input: QueryDeviceInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordUserArgs = {
  input: ResetPasswordUserInput;
};


export type MutationRestartDeviceArgs = {
  input: QueryDeviceInput;
};


export type MutationRestoreUserArgs = {
  input: QueryUserInput;
};


export type MutationRotateScreenArgs = {
  input: UpdateRotateInput;
};


export type MutationScanWifiDeviceArgs = {
  input: ScanWifiDeviceInput;
};


export type MutationSendGroupMessageArgs = {
  input: SendGroupMessageInput;
};


export type MutationSendScreenshotArgs = {
  input: CaptureScreenInput;
};


export type MutationSendWifiDeviceArgs = {
  input: SendWifiInput;
};


export type MutationSettingDeviceArgs = {
  input: SettingDeviceInput;
};


export type MutationStatusConnectWifiArgs = {
  input: StatusConnectWifiInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};


export type MutationUpdateDeviceCurrentPlaylistsArgs = {
  input: UpdateDeviceCurrentPlaylistsInput;
};


export type MutationUpdateDeviceScreenStatusArgs = {
  input: UpdateDeviceStatusInput;
};


export type MutationUpdateDeviceStatusArgs = {
  input: UpdateDeviceStatusInput;
};


export type MutationUpdateFileArgs = {
  input: UpdateMediaInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateGroupPlaylistSchedulesArgs = {
  input: UpdateDeviceSchedulesInput;
};


export type MutationUpdateGroupStatusArgs = {
  input: UpdateGroupStatusInput;
};


export type MutationUpdateMySessionUrlArgs = {
  input: DeviceProxySessionInput;
};


export type MutationUpdatePlaylistArgs = {
  input: UpdatePlaylistInput;
};


export type MutationUpdatePlaylistGroupArgs = {
  input: SetPlaylistToGroup;
};


export type MutationUpdatePlaylistToOtherGroupArgs = {
  input: SetPlaylistToOtherGroup;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateTimeZoneGroupArgs = {
  input: UpdateTimeZoneGroupInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  parentId?: Maybe<Scalars['ID']>;
};


export type MutationVerifyTokenAutoLoginArgs = {
  input: VerifyTokenAutoLoginInput;
};

export type NewTokenAutoLogin = {
  __typename?: 'NewTokenAutoLogin';
  token: Scalars['String'];
};

export enum OsType {
  Android = 'ANDROID',
  Linux = 'LINUX',
  Tizen = 'TIZEN',
  Webos = 'WEBOS'
}

export type OpenTime = {
  __typename?: 'OpenTime';
  /** Time close */
  closeTime: Scalars['Float'];
  /** Day */
  day: DayOfWeek;
  /** is open time */
  isOpen: Scalars['Boolean'];
  /** Time open */
  openTime: Scalars['Float'];
};

export type OpenTimeInput = {
  /** Time close */
  closeTime: Scalars['Float'];
  /** Day */
  day: DayOfWeek;
  /** is open time */
  isOpen: Scalars['Boolean'];
  /** Time open */
  openTime: Scalars['Float'];
};

export type PaginatedCompany = {
  __typename?: 'PaginatedCompany';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Company>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedCompanyUser = {
  __typename?: 'PaginatedCompanyUser';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<CompanyUser>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedDevice = {
  __typename?: 'PaginatedDevice';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Device>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedGroup = {
  __typename?: 'PaginatedGroup';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Group>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedMedia = {
  __typename?: 'PaginatedMedia';
  currentFolder?: Maybe<Media>;
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Media>>;
  s3FolderSize?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedPlaylist = {
  __typename?: 'PaginatedPlaylist';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Playlist>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<User>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type Playlist = {
  __typename?: 'Playlist';
  /** id */
  _id: Scalars['ID'];
  company?: Maybe<Company>;
  companyId: Scalars['ID'];
  createdAt: Scalars['Float'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedByUser?: Maybe<User>;
  editingBy?: Maybe<User>;
  editingById?: Maybe<Scalars['ID']>;
  frames: Array<Frame>;
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  orientation?: Maybe<FrameDirection>;
  resolution?: Maybe<Resolutions>;
  screenType?: Maybe<PlaylistScreenType>;
  status?: Maybe<PlaylistStatus>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Float']>;
};

export enum PlaylistChangeGroupAction {
  Copy = 'COPY',
  Move = 'MOVE'
}

export enum PlaylistScreenType {
  FulScreen = 'FUL_SCREEN',
  SplitScreen = 'SPLIT_SCREEN'
}

export enum PlaylistStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Rejected = 'REJECTED',
  WaitToApprove = 'WAIT_TO_APPROVE'
}

export type PublicUpdateDeviceInfoInput = {
  /** Pi api run at */
  appBackendStartAt?: Maybe<Scalars['Float']>;
  /** chrome current run at */
  appFrontendStartAt?: Maybe<Scalars['Float']>;
  localTime?: Maybe<Scalars['String']>;
  playlistIds: Array<Scalars['String']>;
  screenStatus: DeviceScreenStatus;
};

export type PublicUpdateDeviceScreenStatusInput = {
  status: DeviceScreenStatus;
};

export type PublicUpdateDeviceStatusInput = {
  status: DeviceStatus;
};

export type PublicUpdateRotateInput = {
  direction: DirectionRotateType;
};

export type Query = {
  __typename?: 'Query';
  calculateTotalStorage?: Maybe<Scalars['Float']>;
  companies?: Maybe<PaginatedCompany>;
  company?: Maybe<Company>;
  companyUsers?: Maybe<PaginatedCompanyUser>;
  device?: Maybe<Device>;
  devices?: Maybe<PaginatedDevice>;
  exportCompany?: Maybe<Scalars['String']>;
  getAllTotalInSystem?: Maybe<TotalNumberData>;
  getCurrentSession?: Maybe<DeviceProxySession>;
  getDeviceInfo?: Maybe<Device>;
  getDeviceStatus?: Maybe<DeviceStatus>;
  getDeviceVersion?: Maybe<Scalars['Float']>;
  getGroupPlaylists?: Maybe<PaginatedPlaylist>;
  group?: Maybe<Group>;
  groups?: Maybe<PaginatedGroup>;
  /** Call from pi to check if this is my proxy session */
  isMyProxySession?: Maybe<Scalars['Boolean']>;
  listUsersCanAddToCompany?: Maybe<PaginatedUser>;
  me: User;
  media?: Maybe<Media>;
  medias?: Maybe<PaginatedMedia>;
  pendingGroups?: Maybe<PaginatedGroup>;
  playlist?: Maybe<Playlist>;
  playlists?: Maybe<PaginatedPlaylist>;
  /** Device call lên server để tạo device theo device code nếu chưa có */
  publicGetDeviceByCode?: Maybe<Device>;
  settings?: Maybe<Array<Setting>>;
  settingsByKey: Array<Setting>;
  user?: Maybe<User>;
  /** filter users, "deleted" is filter deleted users */
  users?: Maybe<PaginatedUser>;
};


export type QueryCalculateTotalStorageArgs = {
  input: QueryCompanyInput;
};


export type QueryCompaniesArgs = {
  input: QueryCompaniesInput;
};


export type QueryCompanyArgs = {
  input: QueryCompanyInput;
};


export type QueryCompanyUsersArgs = {
  input: QueryCompanyUsersInput;
};


export type QueryDeviceArgs = {
  input: QueryDeviceInput;
};


export type QueryDevicesArgs = {
  input: QueryDevicesInput;
};


export type QueryGetGroupPlaylistsArgs = {
  input: QueryGroupPlaylistsInput;
};


export type QueryGroupArgs = {
  input: QueryGroupInput;
};


export type QueryGroupsArgs = {
  input: QueryGroupsInput;
};


export type QueryIsMyProxySessionArgs = {
  waitFor: Scalars['Boolean'];
};


export type QueryListUsersCanAddToCompanyArgs = {
  input: QueryUsersCanImportInput;
};


export type QueryMediaArgs = {
  input: QueryMediaInput;
};


export type QueryMediasArgs = {
  input: QueryMediasInput;
};


export type QueryPendingGroupsArgs = {
  input: QueryPendingGroupsInput;
};


export type QueryPlaylistArgs = {
  input: QueryPlaylistInput;
};


export type QueryPlaylistsArgs = {
  input: QueryPlaylistsInput;
};


export type QueryPublicGetDeviceByCodeArgs = {
  input: QueryDeviceByCodeInput;
};


export type QuerySettingsByKeyArgs = {
  input: Scalars['String'];
};


export type QueryUserArgs = {
  input: QueryUserInput;
};


export type QueryUsersArgs = {
  input: QueryUsersInput;
};

export type QueryCompaniesInput = {
  afterId?: Maybe<Scalars['ID']>;
  capacity?: Maybe<Capacity>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  userLimit?: Maybe<UserLimit>;
};

export type QueryCompanyInput = {
  _id: Scalars['ID'];
};

export type QueryCompanyUsersInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
};

export type QueryDeviceByCodeInput = {
  appVersion?: Maybe<Scalars['String']>;
  deviceCode?: Maybe<Scalars['String']>;
  deviceCodeHash?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['String']>;
};

export type QueryDeviceInput = {
  _id: Scalars['ID'];
};

export type QueryDevicesInput = {
  afterId?: Maybe<Scalars['ID']>;
  availableCompany?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  os?: Maybe<OsType>;
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  screen?: Maybe<ScreenType>;
  status?: Maybe<DeviceStatus>;
};

export type QueryGroupInput = {
  _id: Scalars['ID'];
};

export type QueryGroupPlaylistsInput = {
  afterId?: Maybe<Scalars['ID']>;
  groupId: Scalars['ID'];
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
};

export type QueryGroupsInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  status?: Maybe<Array<GroupStatus>>;
};

export type QueryMediaInput = {
  _id: Scalars['ID'];
};

export type QueryMediasInput = {
  afterId?: Maybe<Scalars['ID']>;
  filterType?: Maybe<MediaFilterType>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  order?: Maybe<MediaOrderBy>;
  page?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
  status?: Maybe<MediaStatus>;
  type?: Maybe<FileType>;
};

export type QueryPendingGroupsInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
};

export type QueryPlaylistInput = {
  _id: Scalars['ID'];
};

export type QueryPlaylistsInput = {
  afterId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
};

export type QueryUserInput = {
  _id?: Maybe<Scalars['ID']>;
};

export type QueryUsersCanImportInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
};

export type QueryUsersInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['String']>;
  /** Bao gồm các user đã bị xóa */
  deleted?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  /** Khi tìm kiếm user để gán quyền vào company, set isSearchAll = true để lấy tất cả user */
  isSearchAll?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
};

export enum Roles {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  SuperAdmin = 'SUPER_ADMIN'
}

export type RefreshTokenInput = {
  /** old access token, accept expired token */
  accessToken: Scalars['String'];
  /** Input refresh token */
  refreshToken: Scalars['String'];
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type ResetPasswordUserInput = {
  email: Scalars['String'];
};

export enum Resolutions {
  FullHd = 'FULL_HD',
  Hd = 'HD'
}

export type ScanWifiDeviceInput = {
  _id: Scalars['ID'];
  companyId?: Maybe<Scalars['String']>;
};

export type ScheduleLayoutSettings = {
  __typename?: 'ScheduleLayoutSettings';
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundColorSecond?: Maybe<Scalars['String']>;
  displayMode?: Maybe<DisplayMode>;
  orientation?: Maybe<FrameDirection>;
  resolution?: Maybe<Resolutions>;
  screenType?: Maybe<PlaylistScreenType>;
};

export type ScheduleLayoutSettingsInput = {
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundColorSecond?: Maybe<Scalars['String']>;
  displayMode: DisplayMode;
  orientation: FrameDirection;
  resolution: Resolutions;
  screenType: PlaylistScreenType;
};

export enum ScheduleType {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  SpecialDay = 'SPECIAL_DAY',
  Weekly = 'WEEKLY'
}

export enum ScreenType {
  Led = 'LED',
  Oled = 'OLED',
  Qled = 'QLED'
}

export type SendGroupMessageInput = {
  direction: GroupMessageDirection;
  groupId: Scalars['ID'];
  loop: Scalars['Int'];
  message: Scalars['String'];
  speed: GroupMessageSpeed;
};

export type SendWifiInput = {
  listWifi: Array<WifiTypeInput>;
};

export type SetPlaylistToGroup = {
  _id: Scalars['ID'];
  groupId: Scalars['ID'];
};

export type SetPlaylistToOtherGroup = {
  _id: Scalars['ID'];
  action: PlaylistChangeGroupAction;
  groupId: Scalars['ID'];
};

export type Setting = {
  __typename?: 'Setting';
  _id: Scalars['ID'];
  key: SettingType;
  value: Array<SettingData>;
};

export type SettingData = {
  __typename?: 'SettingData';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SettingDeviceInput = {
  _id: Scalars['ID'];
  subscriptionDate?: Maybe<SubscriptionDateInput>;
  wifi?: Maybe<WifiTypeInput>;
};

export enum SettingType {
  Device = 'DEVICE',
  Upload = 'UPLOAD'
}

export enum Sort {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SpecialDay = {
  __typename?: 'SpecialDay';
  /** id */
  _id: Scalars['ID'];
  closedTime: Scalars['Float'];
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  date: Scalars['Float'];
  openTime: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
};

export type SpecialDayScheduleData = {
  __typename?: 'SpecialDayScheduleData';
  /** fromDate */
  date: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  timeInDay?: Maybe<TimeInDay>;
  toDate?: Maybe<Scalars['String']>;
};

export type SpecialDayScheduleInputData = {
  /** fromDate */
  date: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  timeInDay?: Maybe<TimeInDayInput>;
  toDate?: Maybe<Scalars['String']>;
};

export type StatusConnectWifiInput = {
  password?: Maybe<Scalars['String']>;
  ssid: Scalars['String'];
  status: Scalars['Boolean'];
};

export enum SubscriberType {
  Free = 'FREE',
  NineMonth = 'NINE_MONTH',
  SixMonth = 'SIX_MONTH',
  ThirtySixMonth = 'THIRTY_SIX_MONTH',
  ThreeMonth = 'THREE_MONTH',
  TwelveMonth = 'TWELVE_MONTH',
  TwentyFourMonth = 'TWENTY_FOUR_MONTH'
}

export type Subscription = {
  __typename?: 'Subscription';
  deviceProxySessionSubs?: Maybe<DeviceProxySession>;
  deviceSubs?: Maybe<DevicePubSub>;
  mediaProcess: Media;
};


export type SubscriptionDeviceProxySessionSubsArgs = {
  _id?: Maybe<Scalars['ID']>;
};


export type SubscriptionDeviceSubsArgs = {
  _id?: Maybe<Scalars['ID']>;
};

export type SubscriptionDate = {
  __typename?: 'SubscriptionDate';
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type SubscriptionDateInput = {
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type TimeInDay = {
  __typename?: 'TimeInDay';
  /** Time end */
  endOfDay: Scalars['String'];
  /** Time start */
  startOfDay: Scalars['String'];
};

export type TimeInDayInput = {
  /** Time end */
  endOfDay: Scalars['String'];
  /** Time start */
  startOfDay: Scalars['String'];
};

export type TotalNumberData = {
  __typename?: 'TotalNumberData';
  availableDevices?: Maybe<Scalars['Float']>;
  companies?: Maybe<Scalars['Float']>;
  devices: Scalars['Float'];
  groups?: Maybe<Scalars['Float']>;
  pendingGroups?: Maybe<Scalars['Float']>;
  users: Scalars['Float'];
};

export enum User_Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UpdateCompanyInput = {
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  address_latitude?: Maybe<Scalars['Float']>;
  address_longitude?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Capacity>;
  currentStorageSize?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  endDate?: Maybe<Scalars['Float']>;
  limitUpload: Scalars['Float'];
  name: Scalars['String'];
  openTimes?: Maybe<Array<OpenTimeInput>>;
  phone: Scalars['String'];
  startDate?: Maybe<Scalars['Float']>;
  userLimit?: Maybe<UserLimit>;
};

export type UpdateDeviceCurrentPlaylistsInput = {
  localTime: Scalars['String'];
  playlistIds: Array<Scalars['String']>;
};

export type UpdateDeviceInput = {
  _id: Scalars['ID'];
  companyId?: Maybe<Scalars['ID']>;
  deviceModel?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  hideClock?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  os?: Maybe<OsType>;
  schedules?: Maybe<Array<DevicePlaylistScheduleInput>>;
  screen?: Maybe<ScreenType>;
  yearOfManufacture?: Maybe<Scalars['Float']>;
};

export type UpdateDeviceSchedulesInput = {
  _id: Scalars['ID'];
  groupTimeZone: Scalars['String'];
  layoutSettings: ScheduleLayoutSettingsInput;
  schedules?: Maybe<Array<DevicePlaylistScheduleInput>>;
  schedulesSecond?: Maybe<Array<DevicePlaylistScheduleInput>>;
};

export type UpdateDeviceStatusInput = {
  _id: Scalars['ID'];
  status: DeviceStatus;
};

export type UpdateGroupInput = {
  _id: Scalars['ID'];
  deviceIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type UpdateGroupStatusInput = {
  _id: Scalars['ID'];
  status: GroupStatus;
};

export type UpdateMediaInput = {
  _id: Scalars['ID'];
  filename: Scalars['String'];
};

export type UpdatePlaylistInput = {
  _id: Scalars['ID'];
  companyId?: Maybe<Scalars['ID']>;
  frames?: Maybe<Array<FrameInputType>>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UpdateRotateInput = {
  _id: Scalars['ID'];
  direction: DirectionRotateType;
};

export type UpdateTimeZoneGroupInput = {
  _id: Scalars['ID'];
  timezone: Scalars['String'];
};

export type UpdateUserInput = {
  _id: Scalars['ID'];
  companyId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
};


export type UploadSettingDataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  /** id */
  _id: Scalars['ID'];
  companies?: Maybe<Array<Company>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedByUser?: Maybe<User>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** full name */
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role: Roles;
  status: User_Status;
  tokenExpired?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
  username: Scalars['String'];
};

export enum UserLimit {
  Fifty = 'FIFTY',
  Hundred = 'HUNDRED',
  Thousand = 'THOUSAND',
  Twenty = 'TWENTY'
}

export type VerifyTokenAutoLoginInput = {
  token: Scalars['String'];
};

export enum VideoPreset {
  Video360P = 'Video360P',
  Video480P = 'Video480P',
  Video720P = 'Video720P',
  Video1080P = 'Video1080P'
}

export type VideoProfile = {
  __typename?: 'VideoProfile';
  height?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  preset?: Maybe<VideoPreset>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type VideoProfileInput = {
  path?: Maybe<Scalars['String']>;
  preset?: Maybe<VideoPreset>;
  url?: Maybe<Scalars['String']>;
};

export type WeeklyScheduleData = {
  __typename?: 'WeeklyScheduleData';
  dateRange?: Maybe<DateRange>;
  daysOfWeek: Array<Scalars['Int']>;
  timeInDay?: Maybe<TimeInDay>;
};

export type WeeklyScheduleInputData = {
  dateRange?: Maybe<DateRangeInput>;
  daysOfWeek: Array<Scalars['Int']>;
  timeInDay?: Maybe<TimeInDayInput>;
};

export type WifiType = {
  __typename?: 'WifiType';
  encryptMode: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  signalLevel?: Maybe<Scalars['Int']>;
  ssid: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type WifiTypeInput = {
  encryptMode: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  signalLevel?: Maybe<Scalars['Int']>;
  ssid: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'JWT' }
    & Pick<Jwt, 'expiresAt' | 'role' | 'companyId' | 'token' | 'refreshTokenExpiresAt' | 'refreshToken' | 'isSuperAdmin'>
    & { payload: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'username' | 'role'>
    ) }
  ) }
);

export type CreateTokenAutoLoginMutationVariables = Exact<{
  input: CreateTokenAutoLoginInput;
}>;


export type CreateTokenAutoLoginMutation = (
  { __typename?: 'Mutation' }
  & { createTokenAutoLogin: (
    { __typename?: 'NewTokenAutoLogin' }
    & Pick<NewTokenAutoLogin, 'token'>
  ) }
);

export type VerifyTokenAutoLoginMutationVariables = Exact<{
  input: VerifyTokenAutoLoginInput;
}>;


export type VerifyTokenAutoLoginMutation = (
  { __typename?: 'Mutation' }
  & { verifyTokenAutoLogin: (
    { __typename?: 'JWT' }
    & Pick<Jwt, 'expiresAt' | 'role' | 'companyId' | 'token' | 'refreshTokenExpiresAt' | 'refreshToken'>
    & { payload: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'username' | 'role'>
    ) }
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'ChangePassRess' }
    & Pick<ChangePassRess, 'token' | 'updated'>
  ) }
);

export type LogoutMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type AddNewCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type AddNewCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'address' | 'address_latitude' | 'address_longitude' | 'startDate' | 'endDate'>
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'address' | 'capacity' | 'description' | 'phone' | 'name' | 'email' | 'userLimit'>
    & { openTimes?: Maybe<Array<(
      { __typename?: 'OpenTime' }
      & Pick<OpenTime, 'closeTime' | 'day' | 'isOpen' | 'openTime'>
    )>> }
  )> }
);

export type AssignUserToCompanyMutationVariables = Exact<{
  input: AssignUserInput;
}>;


export type AssignUserToCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignUserToCompany'>
);

export type DeleteCompanyMutationVariables = Exact<{
  input: DeleteCompanyInput;
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type KickOutCompanyMutationVariables = Exact<{
  input: KickOutCompanyInput;
}>;


export type KickOutCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'kickOutCompany'>
);

export type GetListCompanyQueryVariables = Exact<{
  input: QueryCompaniesInput;
}>;


export type GetListCompanyQuery = (
  { __typename?: 'Query' }
  & { companies?: Maybe<(
    { __typename?: 'PaginatedCompany' }
    & Pick<PaginatedCompany, 'currentPage' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'phone' | 'createdAt' | 'description' | 'capacity' | 'userLimit' | 'limitUpload' | 'email' | 'address' | 'address_latitude' | 'address_longitude' | 'startDate' | 'endDate' | 'updatedAt' | '_id' | 'deletedAt'>
    )>> }
  )> }
);

export type GetCompanyByIdQueryVariables = Exact<{
  input: QueryCompanyInput;
}>;


export type GetCompanyByIdQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'address' | 'address_latitude' | 'address_longitude' | 'capacity' | 'description' | 'phone' | 'name' | 'email' | 'userLimit' | 'startDate' | 'endDate' | 'limitUpload' | 'currentStorageSize'>
    & { openTimes?: Maybe<Array<(
      { __typename?: 'OpenTime' }
      & Pick<OpenTime, 'closeTime' | 'day' | 'isOpen' | 'openTime'>
    )>> }
  )> }
);

export type GetCompanyUsersQueryVariables = Exact<{
  input: QueryCompanyUsersInput;
}>;


export type GetCompanyUsersQuery = (
  { __typename?: 'Query' }
  & { companyUsers?: Maybe<(
    { __typename?: 'PaginatedCompanyUser' }
    & Pick<PaginatedCompanyUser, 'currentPage' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'CompanyUser' }
      & Pick<CompanyUser, '_id' | 'name' | 'username' | 'role' | 'phone' | 'email' | 'status'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, '_id' | 'name'>
      )> }
    )>> }
  )> }
);

export type ListUsersCanAddToCompanyQueryVariables = Exact<{
  input: QueryUsersCanImportInput;
}>;


export type ListUsersCanAddToCompanyQuery = (
  { __typename?: 'Query' }
  & { listUsersCanAddToCompany?: Maybe<(
    { __typename?: 'PaginatedUser' }
    & Pick<PaginatedUser, 'currentPage' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'username' | 'email' | 'status' | 'role'>
    )>> }
  )> }
);

export type CalculateTotalStorageQueryVariables = Exact<{
  input: QueryCompanyInput;
}>;


export type CalculateTotalStorageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'calculateTotalStorage'>
);

export type GetAllTotalInSystemQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTotalInSystemQuery = (
  { __typename?: 'Query' }
  & { getAllTotalInSystem?: Maybe<(
    { __typename?: 'TotalNumberData' }
    & Pick<TotalNumberData, 'users' | 'devices' | 'groups' | 'pendingGroups' | 'companies' | 'availableDevices'>
  )> }
);

export type AddNewDeviceMutationVariables = Exact<{
  input: CreateDeviceInput;
}>;


export type AddNewDeviceMutation = (
  { __typename?: 'Mutation' }
  & { createDevice?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'name' | 'deviceCode' | 'deviceModel' | 'os' | 'screen' | 'yearOfManufacture' | 'companyId'>
  )> }
);

export type UpdateDeviceMutationVariables = Exact<{
  input: UpdateDeviceInput;
}>;


export type UpdateDeviceMutation = (
  { __typename?: 'Mutation' }
  & { updateDevice?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'companyId' | 'deviceModel' | 'name' | 'os' | 'screen' | 'uniqueCode' | 'deviceCode' | 'yearOfManufacture'>
  )> }
);

export type RotateScreenMutationVariables = Exact<{
  input: UpdateRotateInput;
}>;


export type RotateScreenMutation = (
  { __typename?: 'Mutation' }
  & { rotateScreen: (
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'companyId' | 'deviceModel' | 'name' | 'os' | 'screen' | 'uniqueCode' | 'deviceCode' | 'yearOfManufacture' | 'direction'>
  ) }
);

export type DeleteDeviceMutationVariables = Exact<{
  input: DeleteDeviceInput;
}>;


export type DeleteDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDevice'>
);

export type UpdateGroupPlaylistSchedulesMutationVariables = Exact<{
  input: UpdateDeviceSchedulesInput;
}>;


export type UpdateGroupPlaylistSchedulesMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupPlaylistSchedules?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, '_id'>
  )> }
);

export type UpdateDeviceStatusMutationVariables = Exact<{
  input: UpdateDeviceStatusInput;
}>;


export type UpdateDeviceStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateDeviceStatus?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'companyId' | 'deviceModel' | 'name' | 'os' | 'screen' | 'uniqueCode' | 'deviceCode' | 'yearOfManufacture' | 'lastScreenshot' | 'status'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )> }
  )> }
);

export type CaptureScreenshotMutationVariables = Exact<{
  input: QueryDeviceInput;
}>;


export type CaptureScreenshotMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'captureScreenshot'>
);

export type ReloadClientAppMutationVariables = Exact<{
  input: QueryDeviceInput;
}>;


export type ReloadClientAppMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reloadClientApp'>
);

export type ScanWifiDeviceMutationVariables = Exact<{
  input: ScanWifiDeviceInput;
}>;


export type ScanWifiDeviceMutation = (
  { __typename?: 'Mutation' }
  & { scanWifiDevice?: Maybe<Array<(
    { __typename?: 'WifiType' }
    & Pick<WifiType, 'encryptMode' | 'ssid' | 'signalLevel'>
  )>> }
);

export type ConnectWifiDeviceMutationVariables = Exact<{
  input: ConnectWifiInput;
}>;


export type ConnectWifiDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'connectWifiDevice'>
);

export type StatusConnectWifiMutationVariables = Exact<{
  input: StatusConnectWifiInput;
}>;


export type StatusConnectWifiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'statusConnectWifi'>
);

export type SettingDeviceMutationVariables = Exact<{
  input: SettingDeviceInput;
}>;


export type SettingDeviceMutation = (
  { __typename?: 'Mutation' }
  & { settingDevice?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'name'>
  )> }
);

export type RestartDeviceMutationVariables = Exact<{
  input: QueryDeviceInput;
}>;


export type RestartDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restartDevice'>
);

export type ClearDeviceStorageMutationVariables = Exact<{
  input: QueryDeviceInput;
}>;


export type ClearDeviceStorageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearDeviceStorage'>
);

export type AdminChangeScreenStatusMutationVariables = Exact<{
  input: ForceUpdateScreenStatusInput;
}>;


export type AdminChangeScreenStatusMutation = (
  { __typename?: 'Mutation' }
  & { adminForceUpdateScreenStatus?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'companyId' | 'deviceModel' | 'name' | 'os' | 'screen' | 'uniqueCode' | 'deviceCode' | 'yearOfManufacture' | 'adminScreenStatus'>
  )> }
);

export type CreateOrUpdateSessionMutationVariables = Exact<{
  deviceId: Scalars['ID'];
}>;


export type CreateOrUpdateSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSession'>
);

export type CloseSessionsMutationVariables = Exact<{ [key: string]: never; }>;


export type CloseSessionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'closeSessions'>
);

export type ListDeviceQueryVariables = Exact<{
  input: QueryDevicesInput;
}>;


export type ListDeviceQuery = (
  { __typename?: 'Query' }
  & { devices?: Maybe<(
    { __typename?: 'PaginatedDevice' }
    & Pick<PaginatedDevice, 'currentPage' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'Device' }
      & Pick<Device, 'appVersion' | 'appBackendStartAt' | 'appFrontendStartAt' | 'hideClock' | 'lastGetInfoAt' | '_id' | 'companyId' | 'deviceModel' | 'name' | 'os' | 'screen' | 'deviceCode' | 'yearOfManufacture' | 'lastScreenshot' | 'status' | 'groupId' | 'lastConnect' | 'direction' | 'localTime' | 'currentPlaylistId' | 'subscriber' | 'offByAdmin' | 'screenStatus' | 'adminScreenStatus'>
      & { subscriptionDate?: Maybe<(
        { __typename?: 'SubscriptionDate' }
        & Pick<SubscriptionDate, 'fromDate' | 'toDate'>
      )>, company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, '_id' | 'name'>
      )>, group?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, '_id' | 'companyId' | 'name' | 'createdAt' | 'deviceIds' | 'timezone' | 'updatedAt' | 'note'>
        & { schedules?: Maybe<Array<(
          { __typename?: 'DevicePlaylistSchedule' }
          & Pick<DevicePlaylistSchedule, 'playlistId' | 'type'>
          & { dailyData?: Maybe<(
            { __typename?: 'DailyScheduleData' }
            & { dateRange?: Maybe<(
              { __typename?: 'DateRange' }
              & Pick<DateRange, 'dateFrom' | 'dateTo'>
            )>, timeInDay?: Maybe<(
              { __typename?: 'TimeInDay' }
              & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
            )> }
          )>, weeklyData?: Maybe<(
            { __typename?: 'WeeklyScheduleData' }
            & Pick<WeeklyScheduleData, 'daysOfWeek'>
            & { dateRange?: Maybe<(
              { __typename?: 'DateRange' }
              & Pick<DateRange, 'dateFrom' | 'dateTo'>
            )>, timeInDay?: Maybe<(
              { __typename?: 'TimeInDay' }
              & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
            )> }
          )>, monthlyData?: Maybe<(
            { __typename?: 'MonthlyScheduleData' }
            & { months?: Maybe<Array<(
              { __typename?: 'MonthInYear' }
              & Pick<MonthInYear, 'days' | 'month'>
            )>>, timeInDay?: Maybe<(
              { __typename?: 'TimeInDay' }
              & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
            )> }
          )>, specialDayData?: Maybe<Array<(
            { __typename?: 'SpecialDayScheduleData' }
            & Pick<SpecialDayScheduleData, 'date' | 'toDate'>
            & { timeInDay?: Maybe<(
              { __typename?: 'TimeInDay' }
              & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
            )> }
          )>> }
        )>>, schedulesSecond?: Maybe<Array<(
          { __typename?: 'DevicePlaylistSchedule' }
          & Pick<DevicePlaylistSchedule, 'playlistId'>
        )>>, layoutSettings?: Maybe<(
          { __typename?: 'ScheduleLayoutSettings' }
          & Pick<ScheduleLayoutSettings, 'screenType'>
        )> }
      )>, currentPlaylist?: Maybe<(
        { __typename?: 'Playlist' }
        & Pick<Playlist, '_id' | 'name' | 'screenType' | 'resolution'>
      )>, currentPlaylists?: Maybe<Array<(
        { __typename?: 'Playlist' }
        & Pick<Playlist, '_id' | 'name' | 'screenType' | 'resolution'>
      )>>, wifi?: Maybe<(
        { __typename?: 'WifiType' }
        & Pick<WifiType, 'encryptMode' | 'ssid' | 'signalLevel' | 'password' | 'status'>
      )> }
    )>> }
  )> }
);

export type DetailDeviceQueryVariables = Exact<{
  input: QueryDeviceInput;
}>;


export type DetailDeviceQuery = (
  { __typename?: 'Query' }
  & { device?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'lastGetInfoAt' | 'companyId' | 'deviceModel' | 'name' | 'os' | 'screen' | 'deviceCode' | 'yearOfManufacture' | 'lastScreenshot' | 'status' | 'lastConnect' | 'direction' | 'localTime' | 'currentPlaylistId' | 'offByAdmin' | 'screenStatus' | 'adminScreenStatus'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )>, currentPlaylist?: Maybe<(
      { __typename?: 'Playlist' }
      & Pick<Playlist, '_id' | 'name' | 'screenType' | 'resolution'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'timezone'>
      & { layoutSettings?: Maybe<(
        { __typename?: 'ScheduleLayoutSettings' }
        & Pick<ScheduleLayoutSettings, 'orientation'>
      )> }
    )> }
  )> }
);

export type DeviceSubsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type DeviceSubsSubscription = (
  { __typename?: 'Subscription' }
  & { deviceSubs?: Maybe<(
    { __typename?: 'DevicePubSub' }
    & Pick<DevicePubSub, 'type' | 'data'>
  )> }
);

export type GetCurrentSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentSessionQuery = (
  { __typename?: 'Query' }
  & { getCurrentSession?: Maybe<(
    { __typename?: 'DeviceProxySession' }
    & Pick<DeviceProxySession, 'connectedAt' | 'deviceCode' | 'startedAt' | 'deviceId' | 'proxyUrl'>
  )> }
);

export type DeviceProxySessionSubsSubscriptionVariables = Exact<{
  deviceId: Scalars['ID'];
}>;


export type DeviceProxySessionSubsSubscription = (
  { __typename?: 'Subscription' }
  & { deviceProxySessionSubs?: Maybe<(
    { __typename?: 'DeviceProxySession' }
    & Pick<DeviceProxySession, 'connectedAt' | 'startedAt' | 'deviceId' | 'deviceCode' | 'proxyUrl'>
  )> }
);

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, '_id' | 'companyId' | 'deviceIds' | 'name' | 'note'>
  )> }
);

export type DeleteGroupMutationVariables = Exact<{
  input: DeleteGroupInput;
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGroup'>
);

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, '_id' | 'companyId' | 'name'>
  )> }
);

export type UpdateTimeZoneGroupMutationVariables = Exact<{
  input: UpdateTimeZoneGroupInput;
}>;


export type UpdateTimeZoneGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeZoneGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, '_id' | 'companyId' | 'name' | 'timezone'>
  )> }
);

export type UpdateGroupStatusMutationVariables = Exact<{
  input: UpdateGroupStatusInput;
}>;


export type UpdateGroupStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupStatus?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, '_id' | 'companyId' | 'name' | 'timezone'>
  )> }
);

export type UpdateSchedulesDeviceGroupMutationVariables = Exact<{
  input: UpdateDeviceSchedulesInput;
}>;


export type UpdateSchedulesDeviceGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupPlaylistSchedules?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, '_id' | 'companyId' | 'timezone'>
    & { schedules?: Maybe<Array<(
      { __typename?: 'DevicePlaylistSchedule' }
      & Pick<DevicePlaylistSchedule, 'createdById' | 'type' | 'playlistId'>
      & { dailyData?: Maybe<(
        { __typename?: 'DailyScheduleData' }
        & { dateRange?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'dateFrom' | 'dateTo'>
        )>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>, monthlyData?: Maybe<(
        { __typename?: 'MonthlyScheduleData' }
        & { months?: Maybe<Array<(
          { __typename?: 'MonthInYear' }
          & Pick<MonthInYear, 'days' | 'month'>
        )>>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>, playlist?: Maybe<(
        { __typename?: 'Playlist' }
        & Pick<Playlist, '_id' | 'companyId' | 'name'>
      )>, specialDayData?: Maybe<Array<(
        { __typename?: 'SpecialDayScheduleData' }
        & Pick<SpecialDayScheduleData, 'date' | 'toDate' | 'name'>
        & { timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>>, weeklyData?: Maybe<(
        { __typename?: 'WeeklyScheduleData' }
        & Pick<WeeklyScheduleData, 'daysOfWeek'>
        & { dateRange?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'dateFrom' | 'dateTo'>
        )>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )> }
    )>>, schedulesSecond?: Maybe<Array<(
      { __typename?: 'DevicePlaylistSchedule' }
      & Pick<DevicePlaylistSchedule, 'type' | 'playlistId'>
      & { dailyData?: Maybe<(
        { __typename?: 'DailyScheduleData' }
        & { dateRange?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'dateFrom' | 'dateTo'>
        )>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>, monthlyData?: Maybe<(
        { __typename?: 'MonthlyScheduleData' }
        & { months?: Maybe<Array<(
          { __typename?: 'MonthInYear' }
          & Pick<MonthInYear, 'days' | 'month'>
        )>>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>, playlist?: Maybe<(
        { __typename?: 'Playlist' }
        & Pick<Playlist, '_id' | 'companyId' | 'name'>
      )>, specialDayData?: Maybe<Array<(
        { __typename?: 'SpecialDayScheduleData' }
        & Pick<SpecialDayScheduleData, 'date' | 'name' | 'toDate'>
        & { timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>>, weeklyData?: Maybe<(
        { __typename?: 'WeeklyScheduleData' }
        & Pick<WeeklyScheduleData, 'daysOfWeek'>
        & { dateRange?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'dateFrom' | 'dateTo'>
        )>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )> }
    )>>, layoutSettings?: Maybe<(
      { __typename?: 'ScheduleLayoutSettings' }
      & Pick<ScheduleLayoutSettings, 'backgroundColor' | 'backgroundColorSecond' | 'displayMode' | 'orientation' | 'resolution' | 'screenType'>
    )> }
  )> }
);

export type SendGroupMessageMutationVariables = Exact<{
  input: SendGroupMessageInput;
}>;


export type SendGroupMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendGroupMessage'>
);

export type UpdatePlaylistToOtherGroupMutationVariables = Exact<{
  input: SetPlaylistToOtherGroup;
}>;


export type UpdatePlaylistToOtherGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePlaylistToOtherGroup'>
);

export type ApproveAllGroupMutationVariables = Exact<{ [key: string]: never; }>;


export type ApproveAllGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveAllGroup'>
);

export type RejectAllGroupMutationVariables = Exact<{ [key: string]: never; }>;


export type RejectAllGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAllGroup'>
);

export type GetListGroupQueryVariables = Exact<{
  input: QueryGroupsInput;
}>;


export type GetListGroupQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<(
    { __typename?: 'PaginatedGroup' }
    & Pick<PaginatedGroup, 'currentPage' | 'hasMore' | 'isLoadMore' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, '_id' | 'companyId' | 'createdAt' | 'createdById' | 'deviceIds' | 'name' | 'note' | 'timezone' | 'updatedById' | 'updatedAt' | 'scheduleVersion' | 'status'>
      & { devices?: Maybe<Array<(
        { __typename?: 'Device' }
        & Pick<Device, '_id' | 'companyId' | 'createdAt' | 'createdById' | 'deletedAt' | 'deviceCode' | 'name' | 'version' | 'yearOfManufacture' | 'uniqueCode' | 'status' | 'screen' | 'os'>
      )>>, company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, '_id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetGroupQueryVariables = Exact<{
  input: QueryGroupInput;
}>;


export type GetGroupQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, '_id' | 'name' | 'companyId' | 'deviceIds' | 'timezone' | 'status'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'address'>
    )>, schedules?: Maybe<Array<(
      { __typename?: 'DevicePlaylistSchedule' }
      & Pick<DevicePlaylistSchedule, 'createdById' | 'type' | 'playlistId'>
      & { dailyData?: Maybe<(
        { __typename?: 'DailyScheduleData' }
        & { dateRange?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'dateFrom' | 'dateTo'>
        )>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>, monthlyData?: Maybe<(
        { __typename?: 'MonthlyScheduleData' }
        & { months?: Maybe<Array<(
          { __typename?: 'MonthInYear' }
          & Pick<MonthInYear, 'days' | 'month'>
        )>>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>, playlist?: Maybe<(
        { __typename?: 'Playlist' }
        & Pick<Playlist, '_id' | 'companyId' | 'name'>
      )>, specialDayData?: Maybe<Array<(
        { __typename?: 'SpecialDayScheduleData' }
        & Pick<SpecialDayScheduleData, 'date' | 'name' | 'toDate'>
        & { timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>>, weeklyData?: Maybe<(
        { __typename?: 'WeeklyScheduleData' }
        & Pick<WeeklyScheduleData, 'daysOfWeek'>
        & { dateRange?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'dateFrom' | 'dateTo'>
        )>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )> }
    )>>, schedulesSecond?: Maybe<Array<(
      { __typename?: 'DevicePlaylistSchedule' }
      & Pick<DevicePlaylistSchedule, 'type' | 'playlistId'>
      & { dailyData?: Maybe<(
        { __typename?: 'DailyScheduleData' }
        & { dateRange?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'dateFrom' | 'dateTo'>
        )>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>, monthlyData?: Maybe<(
        { __typename?: 'MonthlyScheduleData' }
        & { months?: Maybe<Array<(
          { __typename?: 'MonthInYear' }
          & Pick<MonthInYear, 'days' | 'month'>
        )>>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>, playlist?: Maybe<(
        { __typename?: 'Playlist' }
        & Pick<Playlist, '_id' | 'companyId' | 'name'>
      )>, specialDayData?: Maybe<Array<(
        { __typename?: 'SpecialDayScheduleData' }
        & Pick<SpecialDayScheduleData, 'date' | 'name' | 'toDate'>
        & { timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )>>, weeklyData?: Maybe<(
        { __typename?: 'WeeklyScheduleData' }
        & Pick<WeeklyScheduleData, 'daysOfWeek'>
        & { dateRange?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'dateFrom' | 'dateTo'>
        )>, timeInDay?: Maybe<(
          { __typename?: 'TimeInDay' }
          & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
        )> }
      )> }
    )>>, layoutSettings?: Maybe<(
      { __typename?: 'ScheduleLayoutSettings' }
      & Pick<ScheduleLayoutSettings, 'backgroundColor' | 'backgroundColorSecond' | 'displayMode' | 'orientation' | 'resolution' | 'screenType'>
    )> }
  )> }
);

export type GetGroupsPendingApproveQueryVariables = Exact<{
  input: QueryPendingGroupsInput;
}>;


export type GetGroupsPendingApproveQuery = (
  { __typename?: 'Query' }
  & { pendingGroups?: Maybe<(
    { __typename?: 'PaginatedGroup' }
    & Pick<PaginatedGroup, 'currentPage' | 'hasMore' | 'isLoadMore' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, '_id' | 'createdAt' | 'createdById' | 'deviceIds' | 'name' | 'note' | 'timezone' | 'updatedById' | 'updatedAt' | 'scheduleVersion' | 'status'>
      & { devices?: Maybe<Array<(
        { __typename?: 'Device' }
        & Pick<Device, '_id' | 'companyId' | 'createdAt' | 'createdById' | 'deletedAt' | 'deviceCode' | 'name' | 'version' | 'yearOfManufacture' | 'uniqueCode' | 'status' | 'screen' | 'os'>
      )>> }
    )>> }
  )> }
);

export type GetGroupPlaylistsQueryVariables = Exact<{
  input: QueryGroupPlaylistsInput;
}>;


export type GetGroupPlaylistsQuery = (
  { __typename?: 'Query' }
  & { getGroupPlaylists?: Maybe<(
    { __typename?: 'PaginatedPlaylist' }
    & Pick<PaginatedPlaylist, 'currentPage' | 'hasMore' | 'isLoadMore' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'Playlist' }
      & Pick<Playlist, '_id' | 'createdAt' | 'createdById' | 'name' | 'orientation' | 'resolution' | 'status'>
    )>> }
  )> }
);

export type UpdateFileMutationVariables = Exact<{
  input: UpdateMediaInput;
}>;


export type UpdateFileMutation = (
  { __typename?: 'Mutation' }
  & { updateFile?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'path' | 'size' | 'status' | 'canEdit' | 'encoding' | 'createdAt' | 'updatedAt' | 'duration'>
    & { thumbs?: Maybe<Array<(
      { __typename?: 'MediaThumb' }
      & Pick<MediaThumb, 'url'>
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )> }
  )> }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  parentId?: Maybe<Scalars['ID']>;
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'path' | 'size' | 'status' | 'canEdit' | 'encoding' | 'createdAt' | 'updatedAt' | 'duration'>
    & { thumbs?: Maybe<Array<(
      { __typename?: 'MediaThumb' }
      & Pick<MediaThumb, 'url'>
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, parent?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, '_id' | 'updatedAt'>
    )> }
  )> }
);

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput;
}>;


export type CreateFolderMutation = (
  { __typename?: 'Mutation' }
  & { createFolder?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'path' | 'size' | 'status' | 'canEdit' | 'encoding' | 'createdAt' | 'updatedAt'>
    & { thumbs?: Maybe<Array<(
      { __typename?: 'MediaThumb' }
      & Pick<MediaThumb, 'url'>
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )> }
  )> }
);

export type DeleteFileMutationVariables = Exact<{
  input: DeleteMediaInput;
}>;


export type DeleteFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFile'>
);

export type MoveFileMutationVariables = Exact<{
  input: MoveMediaInput;
}>;


export type MoveFileMutation = (
  { __typename?: 'Mutation' }
  & { moveFile?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'path' | 'size' | 'status' | 'canEdit' | 'encoding' | 'createdAt' | 'updatedAt' | 'duration'>
    & { thumbs?: Maybe<Array<(
      { __typename?: 'MediaThumb' }
      & Pick<MediaThumb, 'url'>
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, parent?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, '_id' | 'updatedAt'>
    )> }
  )> }
);

export type ListMediaQueryVariables = Exact<{
  input: QueryMediasInput;
}>;


export type ListMediaQuery = (
  { __typename?: 'Query' }
  & { medias?: Maybe<(
    { __typename?: 'PaginatedMedia' }
    & Pick<PaginatedMedia, 'currentPage' | 'total' | 'totalPage' | 'hasMore' | 'isLoadMore'>
    & { items?: Maybe<Array<(
      { __typename?: 'Media' }
      & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'path' | 'size' | 'status' | 'canEdit' | 'parentId' | 'width' | 'height' | 'encoding' | 'createdAt' | 'updatedAt' | 'createdById' | 'duration'>
      & { thumbs?: Maybe<Array<(
        { __typename?: 'MediaThumb' }
        & Pick<MediaThumb, 'url'>
      )>>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )>, images?: Maybe<Array<(
        { __typename?: 'MediaImage' }
        & Pick<MediaImage, 'height' | 'mimetype' | 'path' | 'url' | 'width'>
      )>>, videoProfiles?: Maybe<Array<(
        { __typename?: 'VideoProfile' }
        & Pick<VideoProfile, 'preset' | 'path' | 'url'>
      )>> }
    )>>, currentFolder?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'path' | 'size' | 'status' | 'canEdit' | 'parentId' | 'encoding' | 'createdAt' | 'updatedAt'>
      & { thumbs?: Maybe<Array<(
        { __typename?: 'MediaThumb' }
        & Pick<MediaThumb, 'url'>
      )>>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'createdById'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )> }
    )> }
  )> }
);

export type MediaProcessSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MediaProcessSubscription = (
  { __typename?: 'Subscription' }
  & { mediaProcess: (
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'path' | 'size' | 'status' | 'canEdit' | 'parentId' | 'encoding' | 'createdAt' | 'updatedAt' | 'createdById' | 'duration'>
    & { thumbs?: Maybe<Array<(
      { __typename?: 'MediaThumb' }
      & Pick<MediaThumb, 'url'>
    )>>, images?: Maybe<Array<(
      { __typename?: 'MediaImage' }
      & Pick<MediaImage, 'width' | 'height' | 'mimetype' | 'path' | 'url'>
    )>>, videoProfiles?: Maybe<Array<(
      { __typename?: 'VideoProfile' }
      & Pick<VideoProfile, 'preset' | 'path' | 'url'>
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )> }
  ) }
);

export type CreatePlaylistMutationVariables = Exact<{
  input: CreatePlaylistInput;
}>;


export type CreatePlaylistMutation = (
  { __typename?: 'Mutation' }
  & { createPlaylist?: Maybe<(
    { __typename?: 'Playlist' }
    & Pick<Playlist, '_id'>
  )> }
);

export type DeletePlaylistMutationVariables = Exact<{
  input: DeletePlaylistInput;
}>;


export type DeletePlaylistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'delPlaylist'>
);

export type UpdatePlaylistMutationVariables = Exact<{
  input: UpdatePlaylistInput;
}>;


export type UpdatePlaylistMutation = (
  { __typename?: 'Mutation' }
  & { updatePlaylist?: Maybe<(
    { __typename?: 'Playlist' }
    & Pick<Playlist, '_id' | 'resolution' | 'status' | 'name' | 'orientation' | 'screenType' | 'companyId' | 'createdAt' | 'createdById' | 'updatedById' | 'editingById' | 'deletedBy'>
    & { frames: Array<(
      { __typename?: 'Frame' }
      & Pick<Frame, 'duration' | 'type' | 'order'>
      & { documentImages?: Maybe<Array<(
        { __typename?: 'MediaImage' }
        & Pick<MediaImage, 'height' | 'mimetype' | 'path' | 'url' | 'width'>
      )>>, videoProfiles?: Maybe<Array<(
        { __typename?: 'VideoProfile' }
        & Pick<VideoProfile, 'preset' | 'path' | 'url'>
      )>> }
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, editingBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, deletedByUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )> }
  )> }
);

export type UpdatePlaylistGroupMutationVariables = Exact<{
  input: SetPlaylistToGroup;
}>;


export type UpdatePlaylistGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePlaylistGroup'>
);

export type PlaylistsQueryVariables = Exact<{
  input: QueryPlaylistsInput;
}>;


export type PlaylistsQuery = (
  { __typename?: 'Query' }
  & { playlists?: Maybe<(
    { __typename?: 'PaginatedPlaylist' }
    & Pick<PaginatedPlaylist, 'currentPage' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'Playlist' }
      & Pick<Playlist, '_id' | 'resolution' | 'name' | 'orientation' | 'companyId' | 'createdAt' | 'createdById' | 'updatedById' | 'editingById' | 'deletedBy' | 'screenType'>
      & { frames: Array<(
        { __typename?: 'Frame' }
        & Pick<Frame, 'duration' | 'webUrl' | 'type'>
        & { media?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'status' | 'path' | 'width' | 'height' | 'size' | 'duration'>
          & { thumbs?: Maybe<Array<(
            { __typename?: 'MediaThumb' }
            & Pick<MediaThumb, 'url'>
          )>>, createdBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'username'>
          )>, images?: Maybe<Array<(
            { __typename?: 'MediaImage' }
            & Pick<MediaImage, 'height' | 'mimetype' | 'path' | 'url' | 'width'>
          )>>, videoProfiles?: Maybe<Array<(
            { __typename?: 'VideoProfile' }
            & Pick<VideoProfile, 'preset' | 'path' | 'url'>
          )>> }
        )> }
      )>, group?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, '_id' | 'name'>
      )>, company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, '_id' | 'name'>
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )>, editingBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )>, deletedByUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )> }
    )>> }
  )> }
);

export type ExportExcelQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportExcelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportCompany'>
);

export type GetPlaylistDetailQueryVariables = Exact<{
  input: QueryPlaylistInput;
}>;


export type GetPlaylistDetailQuery = (
  { __typename?: 'Query' }
  & { playlist?: Maybe<(
    { __typename?: 'Playlist' }
    & Pick<Playlist, '_id' | 'resolution' | 'status' | 'name' | 'orientation' | 'screenType' | 'companyId' | 'createdAt' | 'createdById' | 'updatedById' | 'editingById' | 'deletedBy'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, '_id' | 'name'>
    )>, frames: Array<(
      { __typename?: 'Frame' }
      & Pick<Frame, 'duration' | 'webUrl' | 'type'>
      & { availability?: Maybe<(
        { __typename?: 'Availability' }
        & Pick<Availability, 'enabled' | 'fromDate' | 'toDate'>
      )>, media?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, '_id' | 'filename' | 'type' | 'companyId' | 'mimetype' | 'optimizePath' | 'optimizeUrl' | 'status' | 'path' | 'width' | 'height' | 'size' | 'duration'>
        & { thumbs?: Maybe<Array<(
          { __typename?: 'MediaThumb' }
          & Pick<MediaThumb, 'url'>
        )>>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username'>
        )>, images?: Maybe<Array<(
          { __typename?: 'MediaImage' }
          & Pick<MediaImage, 'height' | 'mimetype' | 'path' | 'url' | 'width'>
        )>>, videoProfiles?: Maybe<Array<(
          { __typename?: 'VideoProfile' }
          & Pick<VideoProfile, 'preset' | 'path' | 'url'>
        )>> }
      )> }
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, editingBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, deletedByUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'phone' | 'username'>
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'phone' | 'username' | 'role'>
  ) }
);

export type CreateUploadSettingMutationVariables = Exact<{
  input: CreateUploadSettingInput;
}>;


export type CreateUploadSettingMutation = (
  { __typename?: 'Mutation' }
  & { createUploadSetting?: Maybe<(
    { __typename?: 'Setting' }
    & Pick<Setting, '_id' | 'key'>
    & { value: Array<(
      { __typename?: 'SettingData' }
      & Pick<SettingData, 'key' | 'value'>
    )> }
  )> }
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { settings?: Maybe<Array<(
    { __typename?: 'Setting' }
    & Pick<Setting, '_id' | 'key'>
    & { value: Array<(
      { __typename?: 'SettingData' }
      & Pick<SettingData, 'key' | 'value'>
    )> }
  )>> }
);

export type AddNewUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type AddNewUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'username' | 'name' | 'phone' | 'role'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'username' | 'name'>
  )> }
);

export type ResetPasswordUserMutationVariables = Exact<{
  input: ResetPasswordUserInput;
}>;


export type ResetPasswordUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPasswordUser'>
);

export type UpdateUserStatusMutationVariables = Exact<{
  input: BlockUserInput;
}>;


export type UpdateUserStatusMutation = (
  { __typename?: 'Mutation' }
  & { blockUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'username' | 'name' | 'status'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  input: QueryUserInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type GetUsersQueryVariables = Exact<{
  input: QueryUsersInput;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'PaginatedUser' }
    & Pick<PaginatedUser, 'currentPage' | 'total' | 'totalPage'>
    & { items?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'role' | 'name' | 'username' | 'phone' | 'email' | 'status' | 'companyId'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, '_id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetUserByIdQueryVariables = Exact<{
  input: QueryUserInput;
}>;


export type GetUserByIdQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'role' | 'phone' | 'name' | 'username' | 'email' | 'status'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )> }
  )> }
);


export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    expiresAt
    payload {
      _id
      name
      username
      role
    }
    role
    companyId
    token
    refreshTokenExpiresAt
    refreshToken
    isSuperAdmin
  }
}
    `;
export const CreateTokenAutoLoginDocument = gql`
    mutation createTokenAutoLogin($input: CreateTokenAutoLoginInput!) {
  createTokenAutoLogin(input: $input) {
    token
  }
}
    `;
export const VerifyTokenAutoLoginDocument = gql`
    mutation verifyTokenAutoLogin($input: VerifyTokenAutoLoginInput!) {
  verifyTokenAutoLogin(input: $input) {
    expiresAt
    payload {
      _id
      name
      username
      role
    }
    role
    companyId
    token
    refreshTokenExpiresAt
    refreshToken
  }
}
    `;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input)
}
    `;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    token
    updated
  }
}
    `;
export const LogoutDocument = gql`
    mutation logout($input: String!) {
  logout(input: $input)
}
    `;
export const AddNewCompanyDocument = gql`
    mutation addNewCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    _id
    address
    address_latitude
    address_longitude
    startDate
    endDate
  }
}
    `;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    _id
    address
    capacity
    description
    phone
    name
    email
    userLimit
    openTimes {
      closeTime
      day
      isOpen
      openTime
    }
  }
}
    `;
export const AssignUserToCompanyDocument = gql`
    mutation assignUserToCompany($input: AssignUserInput!) {
  assignUserToCompany(assignUserInput: $input)
}
    `;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($input: DeleteCompanyInput!) {
  deleteCompany(input: $input)
}
    `;
export const KickOutCompanyDocument = gql`
    mutation kickOutCompany($input: KickOutCompanyInput!) {
  kickOutCompany(input: $input)
}
    `;
export const GetListCompanyDocument = gql`
    query getListCompany($input: QueryCompaniesInput!) {
  companies(input: $input) {
    currentPage
    total
    items {
      name
      phone
      createdAt
      description
      capacity
      userLimit
      limitUpload
      email
      address
      address_latitude
      address_longitude
      startDate
      endDate
      updatedAt
      _id
      deletedAt
    }
    totalPage
  }
}
    `;
export const GetCompanyByIdDocument = gql`
    query getCompanyByID($input: QueryCompanyInput!) {
  company(input: $input) {
    _id
    address
    address_latitude
    address_longitude
    capacity
    description
    phone
    name
    email
    userLimit
    startDate
    endDate
    limitUpload
    currentStorageSize
    openTimes {
      closeTime
      day
      isOpen
      openTime
    }
  }
}
    `;
export const GetCompanyUsersDocument = gql`
    query getCompanyUsers($input: QueryCompanyUsersInput!) {
  companyUsers(input: $input) {
    currentPage
    total
    totalPage
    items {
      _id
      name
      username
      role
      phone
      email
      status
      company {
        _id
        name
      }
    }
  }
}
    `;
export const ListUsersCanAddToCompanyDocument = gql`
    query listUsersCanAddToCompany($input: QueryUsersCanImportInput!) {
  listUsersCanAddToCompany(input: $input) {
    currentPage
    items {
      _id
      name
      username
      email
      status
      role
    }
    total
    totalPage
  }
}
    `;
export const CalculateTotalStorageDocument = gql`
    query calculateTotalStorage($input: QueryCompanyInput!) {
  calculateTotalStorage(input: $input)
}
    `;
export const GetAllTotalInSystemDocument = gql`
    query getAllTotalInSystem {
  getAllTotalInSystem {
    users
    devices
    groups
    pendingGroups
    companies
    availableDevices
  }
}
    `;
export const AddNewDeviceDocument = gql`
    mutation addNewDevice($input: CreateDeviceInput!) {
  createDevice(input: $input) {
    name
    deviceCode
    deviceModel
    os
    screen
    yearOfManufacture
    companyId
  }
}
    `;
export const UpdateDeviceDocument = gql`
    mutation updateDevice($input: UpdateDeviceInput!) {
  updateDevice(input: $input) {
    _id
    companyId
    deviceModel
    name
    os
    screen
    uniqueCode
    deviceCode
    yearOfManufacture
  }
}
    `;
export const RotateScreenDocument = gql`
    mutation rotateScreen($input: UpdateRotateInput!) {
  rotateScreen(input: $input) {
    _id
    companyId
    deviceModel
    name
    os
    screen
    uniqueCode
    deviceCode
    yearOfManufacture
    direction
  }
}
    `;
export const DeleteDeviceDocument = gql`
    mutation deleteDevice($input: DeleteDeviceInput!) {
  deleteDevice(input: $input)
}
    `;
export const UpdateGroupPlaylistSchedulesDocument = gql`
    mutation updateGroupPlaylistSchedules($input: UpdateDeviceSchedulesInput!) {
  updateGroupPlaylistSchedules(input: $input) {
    _id
  }
}
    `;
export const UpdateDeviceStatusDocument = gql`
    mutation updateDeviceStatus($input: UpdateDeviceStatusInput!) {
  updateDeviceStatus(input: $input) {
    _id
    companyId
    company {
      _id
      name
    }
    deviceModel
    name
    os
    screen
    uniqueCode
    deviceCode
    yearOfManufacture
    lastScreenshot
    status
  }
}
    `;
export const CaptureScreenshotDocument = gql`
    mutation captureScreenshot($input: QueryDeviceInput!) {
  captureScreenshot(input: $input)
}
    `;
export const ReloadClientAppDocument = gql`
    mutation reloadClientApp($input: QueryDeviceInput!) {
  reloadClientApp(input: $input)
}
    `;
export const ScanWifiDeviceDocument = gql`
    mutation scanWifiDevice($input: ScanWifiDeviceInput!) {
  scanWifiDevice(input: $input) {
    encryptMode
    ssid
    signalLevel
  }
}
    `;
export const ConnectWifiDeviceDocument = gql`
    mutation connectWifiDevice($input: ConnectWifiInput!) {
  connectWifiDevice(input: $input)
}
    `;
export const StatusConnectWifiDocument = gql`
    mutation statusConnectWifi($input: StatusConnectWifiInput!) {
  statusConnectWifi(input: $input)
}
    `;
export const SettingDeviceDocument = gql`
    mutation SettingDevice($input: SettingDeviceInput!) {
  settingDevice(input: $input) {
    _id
    name
  }
}
    `;
export const RestartDeviceDocument = gql`
    mutation restartDevice($input: QueryDeviceInput!) {
  restartDevice(input: $input)
}
    `;
export const ClearDeviceStorageDocument = gql`
    mutation clearDeviceStorage($input: QueryDeviceInput!) {
  clearDeviceStorage(input: $input)
}
    `;
export const AdminChangeScreenStatusDocument = gql`
    mutation adminChangeScreenStatus($input: ForceUpdateScreenStatusInput!) {
  adminForceUpdateScreenStatus(input: $input) {
    _id
    companyId
    deviceModel
    name
    os
    screen
    uniqueCode
    deviceCode
    yearOfManufacture
    adminScreenStatus
  }
}
    `;
export const CreateOrUpdateSessionDocument = gql`
    mutation createOrUpdateSession($deviceId: ID!) {
  createSession(input: {_id: $deviceId})
}
    `;
export const CloseSessionsDocument = gql`
    mutation closeSessions {
  closeSessions
}
    `;
export const ListDeviceDocument = gql`
    query listDevice($input: QueryDevicesInput!) {
  devices(input: $input) {
    currentPage
    items {
      appVersion
      appBackendStartAt
      appFrontendStartAt
      hideClock
      subscriptionDate {
        fromDate
        toDate
      }
      lastGetInfoAt
      _id
      companyId
      company {
        _id
        name
      }
      deviceModel
      name
      os
      screen
      deviceCode
      yearOfManufacture
      lastScreenshot
      status
      groupId
      group {
        _id
        companyId
        name
        createdAt
        deviceIds
        timezone
        updatedAt
        note
        schedules {
          playlistId
          type
          dailyData {
            dateRange {
              dateFrom
              dateTo
            }
            timeInDay {
              endOfDay
              startOfDay
            }
          }
          weeklyData {
            dateRange {
              dateFrom
              dateTo
            }
            daysOfWeek
            timeInDay {
              endOfDay
              startOfDay
            }
          }
          monthlyData {
            months {
              days
              month
            }
            timeInDay {
              endOfDay
              startOfDay
            }
          }
          specialDayData {
            timeInDay {
              endOfDay
              startOfDay
            }
            date
            toDate
          }
        }
        schedulesSecond {
          playlistId
        }
        layoutSettings {
          screenType
        }
      }
      currentPlaylist {
        _id
        name
        screenType
        resolution
      }
      currentPlaylists {
        _id
        name
        screenType
        resolution
      }
      lastConnect
      direction
      localTime
      currentPlaylistId
      subscriber
      wifi {
        encryptMode
        ssid
        signalLevel
        password
        status
      }
      offByAdmin
      screenStatus
      adminScreenStatus
    }
    total
    totalPage
  }
}
    `;
export const DetailDeviceDocument = gql`
    query detailDevice($input: QueryDeviceInput!) {
  device(input: $input) {
    _id
    lastGetInfoAt
    companyId
    company {
      _id
      name
    }
    deviceModel
    name
    os
    screen
    deviceCode
    yearOfManufacture
    lastScreenshot
    status
    currentPlaylist {
      _id
      name
      screenType
      resolution
    }
    lastConnect
    direction
    localTime
    currentPlaylistId
    offByAdmin
    screenStatus
    adminScreenStatus
    group {
      timezone
      layoutSettings {
        orientation
      }
    }
  }
}
    `;
export const DeviceSubsDocument = gql`
    subscription deviceSubs {
  deviceSubs {
    type
    data
  }
}
    `;
export const GetCurrentSessionDocument = gql`
    query getCurrentSession {
  getCurrentSession {
    connectedAt
    deviceCode
    startedAt
    deviceId
    proxyUrl
  }
}
    `;
export const DeviceProxySessionSubsDocument = gql`
    subscription deviceProxySessionSubs($deviceId: ID!) {
  deviceProxySessionSubs(_id: $deviceId) {
    connectedAt
    startedAt
    deviceId
    deviceCode
    proxyUrl
  }
}
    `;
export const CreateGroupDocument = gql`
    mutation CreateGroup($input: CreateGroupInput!) {
  createGroup(input: $input) {
    _id
    companyId
    deviceIds
    name
    note
  }
}
    `;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($input: DeleteGroupInput!) {
  deleteGroup(input: $input)
}
    `;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($input: UpdateGroupInput!) {
  updateGroup(input: $input) {
    _id
    companyId
    name
  }
}
    `;
export const UpdateTimeZoneGroupDocument = gql`
    mutation UpdateTimeZoneGroup($input: UpdateTimeZoneGroupInput!) {
  updateTimeZoneGroup(input: $input) {
    _id
    companyId
    name
    timezone
  }
}
    `;
export const UpdateGroupStatusDocument = gql`
    mutation updateGroupStatus($input: UpdateGroupStatusInput!) {
  updateGroupStatus(input: $input) {
    _id
    companyId
    name
    timezone
  }
}
    `;
export const UpdateSchedulesDeviceGroupDocument = gql`
    mutation UpdateSchedulesDeviceGroup($input: UpdateDeviceSchedulesInput!) {
  updateGroupPlaylistSchedules(input: $input) {
    _id
    companyId
    schedules {
      createdById
      dailyData {
        dateRange {
          dateFrom
          dateTo
        }
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      monthlyData {
        months {
          days
          month
        }
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      playlist {
        _id
        companyId
        name
      }
      specialDayData {
        date
        timeInDay {
          endOfDay
          startOfDay
        }
        toDate
        name
      }
      weeklyData {
        dateRange {
          dateFrom
          dateTo
        }
        daysOfWeek
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      type
      playlistId
    }
    schedulesSecond {
      dailyData {
        dateRange {
          dateFrom
          dateTo
        }
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      monthlyData {
        months {
          days
          month
        }
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      playlist {
        _id
        companyId
        name
      }
      specialDayData {
        date
        timeInDay {
          endOfDay
          startOfDay
        }
        name
        toDate
      }
      weeklyData {
        dateRange {
          dateFrom
          dateTo
        }
        daysOfWeek
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      type
      playlistId
    }
    timezone
    layoutSettings {
      backgroundColor
      backgroundColorSecond
      displayMode
      orientation
      resolution
      screenType
    }
  }
}
    `;
export const SendGroupMessageDocument = gql`
    mutation sendGroupMessage($input: SendGroupMessageInput!) {
  sendGroupMessage(input: $input)
}
    `;
export const UpdatePlaylistToOtherGroupDocument = gql`
    mutation updatePlaylistToOtherGroup($input: SetPlaylistToOtherGroup!) {
  updatePlaylistToOtherGroup(input: $input)
}
    `;
export const ApproveAllGroupDocument = gql`
    mutation approveAllGroup {
  approveAllGroup
}
    `;
export const RejectAllGroupDocument = gql`
    mutation rejectAllGroup {
  rejectAllGroup
}
    `;
export const GetListGroupDocument = gql`
    query getListGroup($input: QueryGroupsInput!) {
  groups(input: $input) {
    currentPage
    hasMore
    isLoadMore
    items {
      _id
      companyId
      createdAt
      createdById
      deviceIds
      devices {
        _id
        companyId
        createdAt
        createdById
        deletedAt
        deviceCode
        name
        version
        yearOfManufacture
        uniqueCode
        status
        screen
        os
      }
      name
      note
      timezone
      updatedById
      updatedAt
      scheduleVersion
      company {
        _id
        name
      }
      status
    }
    total
    totalPage
  }
}
    `;
export const GetGroupDocument = gql`
    query getGroup($input: QueryGroupInput!) {
  group(input: $input) {
    _id
    name
    company {
      _id
      address
    }
    companyId
    deviceIds
    schedules {
      createdById
      dailyData {
        dateRange {
          dateFrom
          dateTo
        }
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      monthlyData {
        months {
          days
          month
        }
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      playlist {
        _id
        companyId
        name
      }
      specialDayData {
        date
        timeInDay {
          endOfDay
          startOfDay
        }
        name
        toDate
      }
      weeklyData {
        dateRange {
          dateFrom
          dateTo
        }
        daysOfWeek
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      type
      playlistId
    }
    schedulesSecond {
      dailyData {
        dateRange {
          dateFrom
          dateTo
        }
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      monthlyData {
        months {
          days
          month
        }
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      playlist {
        _id
        companyId
        name
      }
      specialDayData {
        date
        timeInDay {
          endOfDay
          startOfDay
        }
        name
        toDate
      }
      weeklyData {
        dateRange {
          dateFrom
          dateTo
        }
        daysOfWeek
        timeInDay {
          endOfDay
          startOfDay
        }
      }
      type
      playlistId
    }
    timezone
    layoutSettings {
      backgroundColor
      backgroundColorSecond
      displayMode
      orientation
      resolution
      screenType
    }
    status
  }
}
    `;
export const GetGroupsPendingApproveDocument = gql`
    query getGroupsPendingApprove($input: QueryPendingGroupsInput!) {
  pendingGroups(input: $input) {
    currentPage
    hasMore
    isLoadMore
    items {
      _id
      createdAt
      createdById
      deviceIds
      devices {
        _id
        companyId
        createdAt
        createdById
        deletedAt
        deviceCode
        name
        version
        yearOfManufacture
        uniqueCode
        status
        screen
        os
      }
      name
      note
      timezone
      updatedById
      updatedAt
      scheduleVersion
      status
    }
    total
    totalPage
  }
}
    `;
export const GetGroupPlaylistsDocument = gql`
    query getGroupPlaylists($input: QueryGroupPlaylistsInput!) {
  getGroupPlaylists(input: $input) {
    currentPage
    hasMore
    isLoadMore
    items {
      _id
      createdAt
      createdById
      name
      orientation
      resolution
      status
    }
    total
    totalPage
  }
}
    `;
export const UpdateFileDocument = gql`
    mutation updateFile($input: UpdateMediaInput!) {
  updateFile(input: $input) {
    _id
    filename
    type
    companyId
    mimetype
    optimizePath
    optimizeUrl
    path
    size
    status
    canEdit
    encoding
    thumbs {
      url
    }
    createdBy {
      username
    }
    createdAt
    updatedAt
    updatedBy {
      username
    }
    duration
  }
}
    `;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!, $parentId: ID) {
  uploadFile(file: $file, parentId: $parentId) {
    _id
    filename
    type
    companyId
    mimetype
    optimizePath
    optimizeUrl
    path
    size
    status
    canEdit
    encoding
    thumbs {
      url
    }
    createdBy {
      username
    }
    createdAt
    updatedAt
    updatedBy {
      username
    }
    parent {
      _id
      updatedAt
    }
    duration
  }
}
    `;
export const CreateFolderDocument = gql`
    mutation createFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    _id
    filename
    type
    companyId
    mimetype
    optimizePath
    optimizeUrl
    path
    size
    status
    canEdit
    encoding
    thumbs {
      url
    }
    createdBy {
      username
    }
    createdAt
    updatedAt
    updatedBy {
      username
    }
  }
}
    `;
export const DeleteFileDocument = gql`
    mutation deleteFile($input: DeleteMediaInput!) {
  deleteFile(input: $input)
}
    `;
export const MoveFileDocument = gql`
    mutation moveFile($input: MoveMediaInput!) {
  moveFile(input: $input) {
    _id
    filename
    type
    companyId
    mimetype
    optimizePath
    optimizeUrl
    path
    size
    status
    canEdit
    encoding
    thumbs {
      url
    }
    createdBy {
      username
    }
    createdAt
    updatedAt
    updatedBy {
      username
    }
    parent {
      _id
      updatedAt
    }
    duration
  }
}
    `;
export const ListMediaDocument = gql`
    query listMedia($input: QueryMediasInput!) {
  medias(input: $input) {
    currentPage
    total
    totalPage
    hasMore
    isLoadMore
    items {
      _id
      filename
      type
      companyId
      mimetype
      optimizePath
      optimizeUrl
      path
      size
      status
      canEdit
      parentId
      width
      height
      encoding
      thumbs {
        url
      }
      createdBy {
        username
      }
      createdAt
      updatedAt
      createdById
      updatedBy {
        username
      }
      images {
        height
        mimetype
        path
        url
        width
      }
      videoProfiles {
        preset
        path
        url
      }
      duration
    }
    currentFolder {
      _id
      filename
      type
      companyId
      mimetype
      optimizePath
      optimizeUrl
      path
      size
      status
      canEdit
      parentId
      encoding
      thumbs {
        url
      }
      createdBy {
        username
        createdById
      }
      createdAt
      updatedAt
      updatedBy {
        username
      }
    }
  }
}
    `;
export const MediaProcessDocument = gql`
    subscription mediaProcess {
  mediaProcess {
    _id
    filename
    type
    companyId
    mimetype
    optimizePath
    optimizeUrl
    path
    size
    status
    canEdit
    parentId
    encoding
    thumbs {
      url
    }
    images {
      width
      height
      mimetype
      path
      url
    }
    videoProfiles {
      preset
      path
      url
    }
    createdBy {
      username
    }
    createdAt
    updatedAt
    createdById
    updatedBy {
      username
    }
    duration
  }
}
    `;
export const CreatePlaylistDocument = gql`
    mutation createPlaylist($input: CreatePlaylistInput!) {
  createPlaylist(input: $input) {
    _id
  }
}
    `;
export const DeletePlaylistDocument = gql`
    mutation deletePlaylist($input: DeletePlaylistInput!) {
  delPlaylist(input: $input)
}
    `;
export const UpdatePlaylistDocument = gql`
    mutation updatePlaylist($input: UpdatePlaylistInput!) {
  updatePlaylist(input: $input) {
    _id
    resolution
    status
    name
    orientation
    screenType
    frames {
      duration
      type
      documentImages {
        height
        mimetype
        path
        url
        width
      }
      videoProfiles {
        preset
        path
        url
      }
      order
    }
    companyId
    createdAt
    createdById
    updatedById
    editingById
    deletedBy
    company {
      _id
      name
    }
    createdBy {
      username
    }
    updatedBy {
      username
    }
    editingBy {
      username
    }
    deletedByUser {
      username
    }
  }
}
    `;
export const UpdatePlaylistGroupDocument = gql`
    mutation updatePlaylistGroup($input: SetPlaylistToGroup!) {
  updatePlaylistGroup(input: $input)
}
    `;
export const PlaylistsDocument = gql`
    query playlists($input: QueryPlaylistsInput!) {
  playlists(input: $input) {
    currentPage
    total
    totalPage
    items {
      _id
      resolution
      name
      orientation
      companyId
      createdAt
      createdById
      updatedById
      editingById
      deletedBy
      screenType
      frames {
        duration
        webUrl
        type
        media {
          _id
          filename
          type
          companyId
          mimetype
          optimizePath
          optimizeUrl
          status
          path
          width
          height
          size
          thumbs {
            url
          }
          createdBy {
            username
          }
          images {
            height
            mimetype
            path
            url
            width
          }
          videoProfiles {
            preset
            path
            url
          }
          duration
        }
      }
      group {
        _id
        name
      }
      company {
        _id
        name
      }
      createdBy {
        username
      }
      updatedBy {
        username
      }
      editingBy {
        username
      }
      deletedByUser {
        username
      }
    }
  }
}
    `;
export const ExportExcelDocument = gql`
    query exportExcel {
  exportCompany
}
    `;
export const GetPlaylistDetailDocument = gql`
    query getPlaylistDetail($input: QueryPlaylistInput!) {
  playlist(input: $input) {
    _id
    resolution
    status
    name
    orientation
    orientation
    name
    group {
      _id
      name
    }
    frames {
      availability {
        enabled
        fromDate
        toDate
      }
      duration
      webUrl
      media {
        _id
        filename
        type
        companyId
        mimetype
        optimizePath
        optimizeUrl
        status
        path
        width
        height
        size
        thumbs {
          url
        }
        createdBy {
          username
        }
        images {
          height
          mimetype
          path
          url
          width
        }
        videoProfiles {
          preset
          path
          url
        }
        duration
      }
      type
    }
    screenType
    companyId
    createdAt
    createdById
    updatedById
    editingById
    deletedBy
    company {
      _id
      name
    }
    createdBy {
      username
    }
    updatedBy {
      username
    }
    editingBy {
      username
    }
    deletedByUser {
      username
    }
  }
}
    `;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    _id
    name
    phone
    username
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    _id
    name
    phone
    username
    role
  }
}
    `;
export const CreateUploadSettingDocument = gql`
    mutation createUploadSetting($input: CreateUploadSettingInput!) {
  createUploadSetting(input: $input) {
    _id
    key
    value {
      key
      value
    }
  }
}
    `;
export const SettingsDocument = gql`
    query settings {
  settings {
    _id
    key
    value {
      key
      value
    }
  }
}
    `;
export const AddNewUserDocument = gql`
    mutation addNewUser($input: CreateUserInput!) {
  createUser(input: $input) {
    _id
    username
    name
    phone
    role
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    _id
    username
    name
  }
}
    `;
export const ResetPasswordUserDocument = gql`
    mutation resetPasswordUser($input: ResetPasswordUserInput!) {
  resetPasswordUser(input: $input)
}
    `;
export const UpdateUserStatusDocument = gql`
    mutation updateUserStatus($input: BlockUserInput!) {
  blockUser(input: $input) {
    _id
    username
    name
    status
  }
}
    `;
export const DeleteUserDocument = gql`
    mutation deleteUser($input: QueryUserInput!) {
  deleteUser(input: $input)
}
    `;
export const GetUsersDocument = gql`
    query getUsers($input: QueryUsersInput!) {
  users(input: $input) {
    currentPage
    items {
      _id
      role
      name
      username
      phone
      email
      status
      companyId
      company {
        _id
        name
      }
    }
    total
    totalPage
  }
}
    `;
export const GetUserByIdDocument = gql`
    query getUserByID($input: QueryUserInput!) {
  user(input: $input) {
    _id
    role
    phone
    name
    username
    email
    status
    company {
      _id
      name
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    login(variables: LoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginMutation>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'login');
    },
    createTokenAutoLogin(variables: CreateTokenAutoLoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateTokenAutoLoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTokenAutoLoginMutation>(CreateTokenAutoLoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createTokenAutoLogin');
    },
    verifyTokenAutoLogin(variables: VerifyTokenAutoLoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VerifyTokenAutoLoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<VerifyTokenAutoLoginMutation>(VerifyTokenAutoLoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'verifyTokenAutoLogin');
    },
    forgotPassword(variables: ForgotPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ForgotPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ForgotPasswordMutation>(ForgotPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'forgotPassword');
    },
    resetPassword(variables: ResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resetPassword');
    },
    changePassword(variables: ChangePasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ChangePasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChangePasswordMutation>(ChangePasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'changePassword');
    },
    logout(variables: LogoutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogoutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LogoutMutation>(LogoutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'logout');
    },
    addNewCompany(variables: AddNewCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddNewCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddNewCompanyMutation>(AddNewCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addNewCompany');
    },
    updateCompany(variables: UpdateCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCompanyMutation>(UpdateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCompany');
    },
    assignUserToCompany(variables: AssignUserToCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AssignUserToCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssignUserToCompanyMutation>(AssignUserToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'assignUserToCompany');
    },
    deleteCompany(variables: DeleteCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCompanyMutation>(DeleteCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCompany');
    },
    kickOutCompany(variables: KickOutCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<KickOutCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<KickOutCompanyMutation>(KickOutCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'kickOutCompany');
    },
    getListCompany(variables: GetListCompanyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetListCompanyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetListCompanyQuery>(GetListCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getListCompany');
    },
    getCompanyByID(variables: GetCompanyByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCompanyByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCompanyByIdQuery>(GetCompanyByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCompanyByID');
    },
    getCompanyUsers(variables: GetCompanyUsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCompanyUsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCompanyUsersQuery>(GetCompanyUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCompanyUsers');
    },
    listUsersCanAddToCompany(variables: ListUsersCanAddToCompanyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListUsersCanAddToCompanyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListUsersCanAddToCompanyQuery>(ListUsersCanAddToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listUsersCanAddToCompany');
    },
    calculateTotalStorage(variables: CalculateTotalStorageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CalculateTotalStorageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CalculateTotalStorageQuery>(CalculateTotalStorageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'calculateTotalStorage');
    },
    getAllTotalInSystem(variables?: GetAllTotalInSystemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllTotalInSystemQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllTotalInSystemQuery>(GetAllTotalInSystemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllTotalInSystem');
    },
    addNewDevice(variables: AddNewDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddNewDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddNewDeviceMutation>(AddNewDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addNewDevice');
    },
    updateDevice(variables: UpdateDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDeviceMutation>(UpdateDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDevice');
    },
    rotateScreen(variables: RotateScreenMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RotateScreenMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RotateScreenMutation>(RotateScreenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'rotateScreen');
    },
    deleteDevice(variables: DeleteDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDeviceMutation>(DeleteDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteDevice');
    },
    updateGroupPlaylistSchedules(variables: UpdateGroupPlaylistSchedulesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateGroupPlaylistSchedulesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGroupPlaylistSchedulesMutation>(UpdateGroupPlaylistSchedulesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateGroupPlaylistSchedules');
    },
    updateDeviceStatus(variables: UpdateDeviceStatusMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDeviceStatusMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDeviceStatusMutation>(UpdateDeviceStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDeviceStatus');
    },
    captureScreenshot(variables: CaptureScreenshotMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CaptureScreenshotMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CaptureScreenshotMutation>(CaptureScreenshotDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'captureScreenshot');
    },
    reloadClientApp(variables: ReloadClientAppMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReloadClientAppMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReloadClientAppMutation>(ReloadClientAppDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'reloadClientApp');
    },
    scanWifiDevice(variables: ScanWifiDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ScanWifiDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ScanWifiDeviceMutation>(ScanWifiDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'scanWifiDevice');
    },
    connectWifiDevice(variables: ConnectWifiDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ConnectWifiDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ConnectWifiDeviceMutation>(ConnectWifiDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'connectWifiDevice');
    },
    statusConnectWifi(variables: StatusConnectWifiMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StatusConnectWifiMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StatusConnectWifiMutation>(StatusConnectWifiDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'statusConnectWifi');
    },
    SettingDevice(variables: SettingDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SettingDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SettingDeviceMutation>(SettingDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SettingDevice');
    },
    restartDevice(variables: RestartDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RestartDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RestartDeviceMutation>(RestartDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'restartDevice');
    },
    clearDeviceStorage(variables: ClearDeviceStorageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ClearDeviceStorageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ClearDeviceStorageMutation>(ClearDeviceStorageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'clearDeviceStorage');
    },
    adminChangeScreenStatus(variables: AdminChangeScreenStatusMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AdminChangeScreenStatusMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AdminChangeScreenStatusMutation>(AdminChangeScreenStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'adminChangeScreenStatus');
    },
    createOrUpdateSession(variables: CreateOrUpdateSessionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateOrUpdateSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrUpdateSessionMutation>(CreateOrUpdateSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createOrUpdateSession');
    },
    closeSessions(variables?: CloseSessionsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CloseSessionsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CloseSessionsMutation>(CloseSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'closeSessions');
    },
    listDevice(variables: ListDeviceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListDeviceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListDeviceQuery>(ListDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listDevice');
    },
    detailDevice(variables: DetailDeviceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DetailDeviceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DetailDeviceQuery>(DetailDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'detailDevice');
    },
    deviceSubs(variables?: DeviceSubsSubscriptionVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeviceSubsSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeviceSubsSubscription>(DeviceSubsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deviceSubs');
    },
    getCurrentSession(variables?: GetCurrentSessionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCurrentSessionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCurrentSessionQuery>(GetCurrentSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCurrentSession');
    },
    deviceProxySessionSubs(variables: DeviceProxySessionSubsSubscriptionVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeviceProxySessionSubsSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeviceProxySessionSubsSubscription>(DeviceProxySessionSubsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deviceProxySessionSubs');
    },
    CreateGroup(variables: CreateGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateGroupMutation>(CreateGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateGroup');
    },
    DeleteGroup(variables: DeleteGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteGroupMutation>(DeleteGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteGroup');
    },
    UpdateGroup(variables: UpdateGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGroupMutation>(UpdateGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateGroup');
    },
    UpdateTimeZoneGroup(variables: UpdateTimeZoneGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateTimeZoneGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTimeZoneGroupMutation>(UpdateTimeZoneGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTimeZoneGroup');
    },
    updateGroupStatus(variables: UpdateGroupStatusMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateGroupStatusMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGroupStatusMutation>(UpdateGroupStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateGroupStatus');
    },
    UpdateSchedulesDeviceGroup(variables: UpdateSchedulesDeviceGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSchedulesDeviceGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateSchedulesDeviceGroupMutation>(UpdateSchedulesDeviceGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateSchedulesDeviceGroup');
    },
    sendGroupMessage(variables: SendGroupMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendGroupMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendGroupMessageMutation>(SendGroupMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendGroupMessage');
    },
    updatePlaylistToOtherGroup(variables: UpdatePlaylistToOtherGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePlaylistToOtherGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePlaylistToOtherGroupMutation>(UpdatePlaylistToOtherGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePlaylistToOtherGroup');
    },
    approveAllGroup(variables?: ApproveAllGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApproveAllGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ApproveAllGroupMutation>(ApproveAllGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'approveAllGroup');
    },
    rejectAllGroup(variables?: RejectAllGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RejectAllGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RejectAllGroupMutation>(RejectAllGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'rejectAllGroup');
    },
    getListGroup(variables: GetListGroupQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetListGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetListGroupQuery>(GetListGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getListGroup');
    },
    getGroup(variables: GetGroupQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetGroupQuery>(GetGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getGroup');
    },
    getGroupsPendingApprove(variables: GetGroupsPendingApproveQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGroupsPendingApproveQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetGroupsPendingApproveQuery>(GetGroupsPendingApproveDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getGroupsPendingApprove');
    },
    getGroupPlaylists(variables: GetGroupPlaylistsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGroupPlaylistsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetGroupPlaylistsQuery>(GetGroupPlaylistsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getGroupPlaylists');
    },
    updateFile(variables: UpdateFileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateFileMutation>(UpdateFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateFile');
    },
    uploadFile(variables: UploadFileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadFileMutation>(UploadFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uploadFile');
    },
    createFolder(variables: CreateFolderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateFolderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateFolderMutation>(CreateFolderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createFolder');
    },
    deleteFile(variables: DeleteFileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteFileMutation>(DeleteFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteFile');
    },
    moveFile(variables: MoveFileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MoveFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MoveFileMutation>(MoveFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'moveFile');
    },
    listMedia(variables: ListMediaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListMediaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListMediaQuery>(ListMediaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listMedia');
    },
    mediaProcess(variables?: MediaProcessSubscriptionVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MediaProcessSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<MediaProcessSubscription>(MediaProcessDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'mediaProcess');
    },
    createPlaylist(variables: CreatePlaylistMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePlaylistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePlaylistMutation>(CreatePlaylistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPlaylist');
    },
    deletePlaylist(variables: DeletePlaylistMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeletePlaylistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeletePlaylistMutation>(DeletePlaylistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deletePlaylist');
    },
    updatePlaylist(variables: UpdatePlaylistMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePlaylistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePlaylistMutation>(UpdatePlaylistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePlaylist');
    },
    updatePlaylistGroup(variables: UpdatePlaylistGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePlaylistGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePlaylistGroupMutation>(UpdatePlaylistGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePlaylistGroup');
    },
    playlists(variables: PlaylistsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PlaylistsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PlaylistsQuery>(PlaylistsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'playlists');
    },
    exportExcel(variables?: ExportExcelQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ExportExcelQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExportExcelQuery>(ExportExcelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'exportExcel');
    },
    getPlaylistDetail(variables: GetPlaylistDetailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPlaylistDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPlaylistDetailQuery>(GetPlaylistDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPlaylistDetail');
    },
    updateProfile(variables: UpdateProfileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateProfileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateProfileMutation>(UpdateProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateProfile');
    },
    me(variables?: MeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeQuery>(MeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'me');
    },
    createUploadSetting(variables: CreateUploadSettingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateUploadSettingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUploadSettingMutation>(CreateUploadSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUploadSetting');
    },
    settings(variables?: SettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SettingsQuery>(SettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'settings');
    },
    addNewUser(variables: AddNewUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddNewUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddNewUserMutation>(AddNewUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addNewUser');
    },
    updateUser(variables: UpdateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserMutation>(UpdateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUser');
    },
    resetPasswordUser(variables: ResetPasswordUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetPasswordUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetPasswordUserMutation>(ResetPasswordUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resetPasswordUser');
    },
    updateUserStatus(variables: UpdateUserStatusMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserStatusMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserStatusMutation>(UpdateUserStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserStatus');
    },
    deleteUser(variables: DeleteUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteUserMutation>(DeleteUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteUser');
    },
    getUsers(variables: GetUsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUsersQuery>(GetUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUsers');
    },
    getUserByID(variables: GetUserByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserByIdQuery>(GetUserByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserByID');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;